import React, { useState } from 'react';
import { Modal, PopupPortal } from '../index';
import { Button, Checkbox } from '../../controls';
import { useTranslation } from 'react-i18next';
import { ColumnType } from '../../../models';
import EmptyButton from '../../controls/Button/EmptyButton';

interface IProps<T> {
  options: ColumnType<T>[];
  onColumnsView: (newOptions: ColumnType<T>[]) => void;
  onRestoreToDefault: () => void;
}

export function ShowColumns<T>({ options, onColumnsView, onRestoreToDefault }: IProps<T>) {
  const { t } = useTranslation();
  const [isOpenShowColumnModal, setIsOpenShowColumnModal] = useState<boolean>(false);
  const [newOptions, setNewOptions] = useState<ColumnType<T>[]>(options);

  const handleCheckField = (option: ColumnType<T>, index: number) => {
    const temArray = [...newOptions];
    temArray[index].isShow = !temArray[index].isShow;
    setNewOptions(temArray);
  };

  const handleCloseModal = () => {
    setNewOptions(options);
    setIsOpenShowColumnModal(false);
  };

  const handleSave = () => {
    onColumnsView(newOptions);
  };

  const handleRestoreToDefault = (e: any) => {
    e.preventDefault();
    onRestoreToDefault();
  };

  return (
    <>
      <div className="b-rdgTable__cellCenter" onClick={e => e.stopPropagation()}>
        <EmptyButton
          onClick={() => setIsOpenShowColumnModal(true)}
          className="b-button-icon -colorNavy -mh2  show-hide-column"
        />
      </div>
      {isOpenShowColumnModal ? (
        <PopupPortal portalElementId={`show-hide-columns`}>
          <Modal
            width={545}
            className={'b-filtersModal__mobileModal'}
            title={t('common:label.columns')}
            onClose={handleCloseModal}
          >
            <div className="b-filtersModal">
              <div className="b-filtersModal__list b-filtersModal__list_fullWidth">
                <a className={'links'} onClick={handleRestoreToDefault}>
                  {t('common:btn.restore-defaults')}
                </a>
                <ul>
                  {newOptions.map((option, i) => {
                    return (
                      <li key={`filter-${option.key}`} className="d-flex w-100 justify-content-between mt-4">
                        <div className="b-resourceForm__publicLabel">
                          <span className={'checkbox-text'}>{option.name}</span>
                        </div>
                        <Checkbox
                          id={`show-hide-check${option.key}`}
                          onChange={() => handleCheckField(option, i)}
                          checked={!!option.isShow}
                          className="w-auto"
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="b-filtersModal__buttons">
              <Button
                type={'transparency'}
                onPress={handleCloseModal}
                title={t('common:btn.cancel')}
                size={'large'}
              />
              <Button
                type={'orange-light'}
                onPress={handleSave}
                title={t('common:btn.save')}
                size={'large'}
              />
            </div>
          </Modal>
        </PopupPortal>
      ) : null}
    </>
  );
}
