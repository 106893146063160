import React from 'react';
import { SortDirection, useRowSelection } from 'react-data-grid';
import useHasAccess from '../../../../../hooks/beloved/adminTool/user-has-access';
import { BelElmModel, ColumnType, TableDataEntities } from '../../../../../models';
import { basicDateFormat, getBelovedElmStatusClass } from '../../../../../helpers';
import { BelovedPermissions } from '../../../../../constants/roles';
import { Checkbox } from '../../../../controls';
import { t } from 'i18next';
import { useHistory } from 'react-router';
import { ROOTS } from '../../../../../routes/routes';
import { TableDateFilter } from '../../../../common';
import { useTableDataV2 } from '../../../../../hooks/common/use-table-data-v2';

const getArrClass = (direction: SortDirection | undefined) => {
  if (direction === 'DESC') return '-reverse';
  if (!direction) return '-hide';
  return '';
};

interface ColumnsHandlers {
  onCellClick: (fieldName: string, elm: BelElmModel) => void;
}

export const getColumns = ({ onCellClick }: ColumnsHandlers): ColumnType<BelElmModel>[] => [
  {
    key: 'organization_name',
    name: t('common:column.organization-name'),
    width: 160,
    sortable: true,
    editable: false,
    resizable: true,
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'affiliation',
    name: t('common:column.affiliation'),
    width: 130,
    sortable: true,
    editable: false,
    resizable: true,
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'org_type',
    name: t('common:column.type'),
    sortable: true,
    editable: false,
    resizable: true,
    headerCellClass: 'b-rdgTable__headerCell',
    formatter(props) {
      return <>{props.row.type}</>;
    },
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'status_name',
    name: t('common:column.status'),
    width: 140,
    sortable: true,
    editable: false,
    resizable: true,
    formatter(props) {
      return (
        <div className="b-equityAudits__elmStatus">
          <span className={`b-tableStatusMark ${getBelovedElmStatusClass(props.row.status_id)}`}>
            {props.row.status_name}
          </span>
          <div>
            {props.row.status_percent !== null ? `${props.row.status_percent}%` : t('common:column.n-a')}
          </div>
        </div>
      );
    },
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'administration_type',
    name: t('common:label.administration-type'),
    width: 90,
    sortable: true,
    isShow: false,
    editable: false,
    resizable: true,
    formatter(props) {
      return <>{props.row.administration_type || t('common:column.n-a')}</>;
    },
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'created_by',
    name: t('common:label.created-by'),
    width: 90,
    sortable: true,
    editable: false,
    isShow: false,
    resizable: true,
    formatter(props) {
      const history = useHistory();
      const handleClick = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        history.push(ROOTS.BELOVED_PM_PROFILE.replace(':orgId', `${props.row.organization_id}`));
      };
      return (
        <a className="b-link" href="#" onClick={handleClick}>
          {props.row.created_by.name}
        </a>
      );
    },
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'created_at',
    name: t('common:column.create-date'),
    width: 90,
    sortable: true,
    editable: false,
    isShow: false,
    resizable: true,
    formatter(props) {
      return (
        <>{props.row.created_at ? basicDateFormat(new Date(props.row.created_at)) : t('common:column.n-a')}</>
      );
    },
    headerRenderer(props) {
      const { handleSelectDateFilter, query } = useTableDataV2<BelElmModel>({
        baseUrl: 'beloved/elm/',
        entityName: TableDataEntities.ELM,
      });
      if (query.dateFilters) {
        return (
          <TableDateFilter
            dateFilterId={'created_at'}
            selectedFilters={query.dateFilters['created_at']}
            periodFieldName={'created_at_period'}
            dateRangeFieldName={'created_at_date_range'}
            onDateFilterChanged={handleSelectDateFilter}
            rdgProps={props}
            fiscalOption={true}
          />
        );
      }
    },
  },
  {
    key: 'start_date',
    name: t('common:column.start-date'),
    width: 90,
    sortable: true,
    editable: false,
    resizable: true,
    formatter(props) {
      return (
        <>{props.row.start_date ? basicDateFormat(new Date(props.row.start_date)) : t('common:column.n-a')}</>
      );
    },
    headerRenderer(props) {
      const { handleSelectDateFilter, query } = useTableDataV2<BelElmModel>({
        baseUrl: 'beloved/elm/',
        entityName: TableDataEntities.ELM,
      });
      if (query.dateFilters) {
        return (
          <TableDateFilter
            dateFilterId={'start_date'}
            selectedFilters={query.dateFilters['start_date']}
            periodFieldName={'start_date_period'}
            dateRangeFieldName={'start_date_date_range'}
            onDateFilterChanged={handleSelectDateFilter}
            rdgProps={props}
            fiscalOption={true}
          />
        );
      }
    },
  },
  {
    key: 'end_date',
    name: t('common:column.end-date'),
    width: 90,
    sortable: true,
    editable: false,
    resizable: true,
    formatter(props) {
      return (
        <>{props.row.end_date ? basicDateFormat(new Date(props.row.end_date)) : t('common:column.n-a')}</>
      );
    },
    headerRenderer(props) {
      const { handleSelectDateFilter, query } = useTableDataV2<BelElmModel>({
        baseUrl: 'beloved/elm/',
        entityName: TableDataEntities.ELM,
      });
      if (query.dateFilters) {
        return (
          <TableDateFilter
            dateFilterId={'end_date'}
            selectedFilters={query.dateFilters['end_date']}
            periodFieldName={'end_date_period'}
            dateRangeFieldName={'end_date_date_range'}
            onDateFilterChanged={handleSelectDateFilter}
            rdgProps={props}
            fiscalOption={true}
          />
        );
      }
    },
  },
  {
    key: 'debrief_leader',
    name: t('common:column.Beloved-Leader'),
    width: 100,
    sortable: true,
    editable: false,
    resizable: true,
    formatter(props) {
      return <>{props.row.beloved_leader ? props.row.beloved_leader.name : t('common:column.n-a')}</>;
    },
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'tools',
    name: '',
    disableDrag: true,
    width: 40,
    maxWidth: 40,
    formatter: props => {
      const navToReport = (e: any) => {
        e.stopPropagation();
        onCellClick('navReport', props.row);
      };
      const hasAccessToElmReport = useHasAccess(BelovedPermissions.ELM_VIEW_REPORT);

      return (
        <div className="b-rdgTable__cellCenter">
          {props.row.has_org_report && hasAccessToElmReport ? (
            <button onClick={navToReport} className="b-button-icon -colorNavy -mh2  mobile" />
          ) : null}
        </div>
      );
    },
  },
  {
    key: 'actions',
    name: '',
    width: 40,
    maxWidth: 40,
    disableDrag: true,
    headerRenderer(props) {
      return (
        <Checkbox
          className={`b-equityAudits__checkbox-header`}
          checked={props.allRowsSelected}
          onChange={e => props.onAllRowsSelectionChange(e.target.checked)}
        />
      );
    },
    formatter: props => {
      const [isRowSelected, onRowSelectionChange] = useRowSelection();

      return (
        <div className="b-rdgTable__cellEnd">
          <div className={'checkBox b-equityAudits__checkbox'} onClick={e => e.stopPropagation()}>
            <Checkbox
              checked={isRowSelected}
              onChange={e => {
                onRowSelectionChange({
                  row: props.row,
                  checked: e.target.checked,
                  isShiftClick: false,
                });
              }}
            />
          </div>
        </div>
      );
    },
  },
];
