import { Column, HeaderRendererProps, SortColumn } from 'react-data-grid';
import { KeyValueModel } from '../key-value.model';
import { PaginationModel } from '../global';

export interface ColumnType<T> extends Column<T> {
  isShow?: boolean;
  width?: number;
  disableDrag?: boolean;
  headerRenderer?(props: HeaderRendererProps<T>): any;
}

export enum TableDataEntities {
  RESOURCES = 'resources',
  RESOURCES_FAVORITE = 'resources-favorite',
  RESOURCES_RECOMMEND = 'resources-recommend',
  ORG_RESOURCES = 'org_resources',
  LINK_RESOURCE_LIST = 'link_resource_list',
  ORGANIZATIONS = 'organizations',
  EA = 'ea',
  ELM = 'elm',
  COHORT_RESOURCES = 'cohort_resources',
  COHORT_PARTICIPANTS = 'cohort_participants',
  COHORT_ORGANIZATIONS = 'cohort_organizations',
  AT_ORG_USERS = 'at_org_users',
  AT_BEL_USERS = 'at_bel_users',
  EWP_PRIORITY_LIST = 'EWP_PRIORITY_LIST',
  EWP_GOAL_LIST = 'EWP_GOAL_LIST',
  EWP_STRATS_LIST = 'EWP_STRATS_LIST',
  EWP_KA_LIST = 'EWP_KA_LIST',
  BEL_EWP_LIST = 'BEL_EWP_LIST',
  EWP_REVIEW_ITEMS = 'EWP_REVIEW_ITEMS',
  EWP_USERS = 'EWP_USERS',
  DEBRIEFS = 'debriefs',
  ORG_USERS = 'org_users',
  ELM_USER_LIST = 'elm_user_list',
  BEL_ORG_ASSIGNMENT_LIST = 'bel_org_assignment_list',
  BELOVED_MESSAGE_ORGANIZATIONS = 'BELOVED_MESSAGE_ORGANIZATIONS',
  BELOVED_MESSAGES = 'BELOVED_MESSAGES',
  CREDIT_LIST = 'credit_list',
  AVG = 'averages',
  AVG_SAVED = 'averages_saved',
  AVG_SAVED_EAs = 'averages_saved_EAs',
}

export type DataTableFilterModel = KeyValueModel<(number | string | boolean)[]>;
export type DataTableDateFilterModel = KeyValueModel<KeyValueModel<(number | string)[]>>;

export interface QueryParamsModel {
  sorting: SortColumn | null;
  search: string;
  page_size: number;
  page: number;
  filters: DataTableFilterModel;
  dateFilters: DataTableDateFilterModel;
  extraData?: KeyValueModel<any>;
}

export type ReqParamsModel = KeyValueModel<number | string>;

export interface TableItemModel {
  list: PaginationModel<any[]> | null;
  query: QueryParamsModel;
}

export interface UseTableDataConfig {
  baseUrl: string;
  entityName: TableDataEntities;
  enableSelectedEntities?: boolean;
  extraData?: KeyValueModel<any>;
}
