import {
  AddNewUserOrganizationModel,
  CreateOrganizationModel,
  OrganizationRoleModel,
  OrganizationRoleTreeModel,
  ApiAddressModel,
  ISelectOptions,
  KeyValueModel,
} from '..';
import { UserTypes } from '../../constants/roles';

export interface JoinRequestModel {
  name: string;
  division: string;
  email: string;
  is_declined: boolean;
  popup_seen: boolean;
}

export interface AuthUserBelovedData {
  role_ids: number[];
  role_name: string[];
  permissions: string[];
  user_type_id: UserTypes.BELOVED_TEAM;
  user_type_name: string;
  share_resource_to_cohort: boolean;
  show_tasks: boolean;
}

export enum ThemeClassName {
  default = 'default',
  dark = 'dark',
  light = 'light',
}

export enum ThemeEnum {
  default = 1,
  dark = 2,
  light = 3,
}

type ThemeType = ThemeEnum;

export interface SettingsModel {
  confirmation_popup: boolean;
  show_task_notification_logic: boolean;
  language: string;
  theme: ThemeType;
}

export interface AuthUserModel {
  login: boolean;
  next_step: number;
  next_step_description: string;
  organization_data?: CreateOrganizationModel;
  user: UserModel;
  orgs_role?: OrganizationRoleModel[];
  orgs_tree?: OrganizationRoleTreeModel[];
  join_request?: JoinRequestModel;
  beloved_user?: AuthUserBelovedData;
  home_page_msg?: string[];
}

interface UserNotificationModel {
  need_phone_number: boolean;
  permission_edit_phone: boolean;
  show_task_notification_logic: boolean;
}

interface UserModel {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  date_joined?: string;
  division?: string;
  secondary_email?: string;
  res_lid_ea_days: number;
  show_res_lib: boolean;
  has_res_access: boolean;
  title?: string;
  settings: SettingsModel;
  avatar: string | null;
  notification: UserNotificationModel;
}

export interface ProfileModel {
  avatar: string | null;
  avatar_id: number | null;
  education_data: ISelectOptions;
  ethnicity_data?: ISelectOptions[];
  gender_data: ISelectOptions[];
  id: number;
  language: string;
  office_address: ApiAddressModel;
  office_type: number;
  pronouns_data: ISelectOptions;
  race_data: ISelectOptions[];
  phone_number: string;
  role: {
    title: string;
    division: string;
  };
  user_id: number;
  pwd_last_update: string;
  first_name: string;
  last_name: string;
  email: string;
  secondary_email: string;
  date_joined: string;
  pm_email: string;
  org_name: string;
  verified_secondary_email: boolean;
  biography?: string;
}

export interface AddNewUserModel {
  email: string;
  first_name: string;
  last_name: string;
  organizations: AddNewUserOrganizationModel[];
}

export interface PasswordModel {
  old_pass: string;
  new_pass: string;
  confirmPassword: string;
}
