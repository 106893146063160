import {
  BelResourceShortModel,
  CheckedOptionsModel,
  ISelectOptions,
  ResourceShareEnum,
  ResourceShareOrgType,
} from '../../../../../models';
import React, { FC, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../common';
import { AsyncSelect, Button, SimpleTooltip } from '../../../../controls';
import { Popover } from 'react-tiny-popover';
import {
  getAvailableCohort,
  getAvailableCohortSession,
  getAvailableOrg,
  linkAvailableCohort,
  linkAvailableCohortSession,
  linkAvailableOrg,
} from '../../../../../store/beloved/resources/resourcesActions';
import { useDispatch, useSelector } from 'react-redux';
import { ResourceStatuses } from '../../../../../constants';
import { StateType } from '../../../../../store/reducers';
import { BelovedPermissions } from '../../../../../constants/roles';

interface IProps {
  selectedEntitiesIds: number[];
  data: BelResourceShortModel[];
  customHandleButton?: ReactElement<any, any>;
  type?: 'card' | 'table';
}

export const ResourceShare: FC<IProps> = ({ selectedEntitiesIds, data, customHandleButton, type }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state: StateType) => state.profile.authUser);
  const [tempSelect, setTempSelect] = useState<CheckedOptionsModel[]>([]);
  const [defaultShareOptions, setDefaultShareOptions] = useState<ISelectOptions[]>([]);
  const [defaultCohortOptions, setDefaultCohortOptions] = useState<ISelectOptions[]>([]);
  const [shareSelectedCohort, setShareSelectedCohort] = useState<ISelectOptions<number> | null>();
  const [isSharePopover, setIsSharePopover] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<ResourceShareEnum | null>(null);
  const isAllowCohorts = user?.beloved_user?.permissions.includes(BelovedPermissions.COHORT_LIST);
  const isAllowToShareToCohort = user?.beloved_user?.share_resource_to_cohort;

  selectedEntitiesIds = selectedEntitiesIds.filter(id => {
    let res = false;
    data.forEach(resource => {
      if (
        resource.id === id &&
        resource.status === ResourceStatuses.PUBLISH &&
        resource.distribution.id !== 1
      ) {
        res = true;
      }
    });
    return res;
  });

  const closeModal = () => {
    setOpenModal(null);
    setIsSharePopover(false);
    setTempSelect([]);
    setShareSelectedCohort(null);
    setDefaultShareOptions([]);
  };

  const handleShare = () => {
    if (openModal === ResourceShareEnum.ORGANIZATION_PM || openModal === ResourceShareEnum.ORGANIZATION_ALL) {
      dispatch(
        linkAvailableOrg({
          data: {
            organization_ids: tempSelect.map(t => Number(t.value)) || [],
            type_id: ResourceShareOrgType[openModal],
            resource_ids: selectedEntitiesIds,
          },
          callback: closeModal,
        }),
      );
    } else if (openModal === ResourceShareEnum.COHORT_GENERAL) {
      dispatch(
        linkAvailableCohort({
          data: {
            cohort_ids: tempSelect.map(t => Number(t.value)) || [],
            resource_ids: selectedEntitiesIds,
          },
          callback: closeModal,
        }),
      );
    } else if (openModal === ResourceShareEnum.COHORT_SESSION) {
      dispatch(
        linkAvailableCohortSession({
          data: {
            cohort_session_ids: tempSelect.map(t => Number(t.value)) || [],
            resource_ids: selectedEntitiesIds,
          },
          callback: closeModal,
        }),
      );
    }
  };

  const handleChangeTempSelect = (value: ISelectOptions<number>[]) => {
    setTempSelect(value);
  };

  const shareGetAvailableCallback = (
    res: ISelectOptions[],
    callback?: (options: ISelectOptions[]) => void,
  ) => {
    setDefaultShareOptions(res);
    callback && callback(res);
  };

  const loadOptions = (inputValue?: string, callback?: (options: ISelectOptions[]) => void) => {
    const body = {
      search: inputValue || '',
      resource_ids: selectedEntitiesIds,
    };
    if (openModal === ResourceShareEnum.ORGANIZATION_PM || openModal === ResourceShareEnum.ORGANIZATION_ALL) {
      dispatch(
        getAvailableOrg({
          data: {
            ...body,
            type_id: ResourceShareOrgType[openModal],
          },
          callback: res => shareGetAvailableCallback(res, callback),
        }),
      );
    } else if (openModal === ResourceShareEnum.COHORT_GENERAL) {
      dispatch(
        getAvailableCohort({
          data: body,
          callback: res => shareGetAvailableCallback(res, callback),
        }),
      );
    } else if (openModal === ResourceShareEnum.COHORT_SESSION) {
      dispatch(
        getAvailableCohortSession({
          data: body,
          cohort_id: shareSelectedCohort?.value || 0,
          callback: res => shareGetAvailableCallback(res, callback),
        }),
      );
    }
  };

  const loadCohortOptions = (inputValue?: string, callback?: (options: ISelectOptions[]) => void) => {
    dispatch(
      getAvailableCohort({
        data: {
          search: inputValue || '',
          resource_ids: selectedEntitiesIds,
        },
        callback: res => {
          setDefaultCohortOptions(res);
          callback && callback(res);
        },
      }),
    );
  };

  const handleOpenModal = (type: ResourceShareEnum) => {
    setIsSharePopover(false);
    setOpenModal(type);
    getDefaultOptions(type);
  };

  const getDefaultOptions = (type: ResourceShareEnum) => {
    if (type === ResourceShareEnum.ORGANIZATION_PM || type === ResourceShareEnum.ORGANIZATION_ALL) {
      dispatch(
        getAvailableOrg({
          data: {
            search: '',
            type_id: ResourceShareOrgType[type],
            resource_ids: selectedEntitiesIds,
          },
          callback: (res: ISelectOptions[]) => {
            setDefaultShareOptions(res);
          },
        }),
      );
    } else if (type === ResourceShareEnum.COHORT_GENERAL || type === ResourceShareEnum.COHORT_SESSION) {
      dispatch(
        getAvailableCohort({
          data: {
            search: '',
            resource_ids: selectedEntitiesIds,
          },
          callback: (res: ISelectOptions[]) => {
            if (type === ResourceShareEnum.COHORT_GENERAL) {
              setDefaultShareOptions(res);
            } else if (type === ResourceShareEnum.COHORT_SESSION) {
              setDefaultCohortOptions(res);
            }
          },
        }),
      );
    }
  };

  const getPlaceholderName = (type: ResourceShareEnum) => {
    if (type === ResourceShareEnum.ORGANIZATION_PM || openModal === ResourceShareEnum.ORGANIZATION_ALL) {
      return t('common:label.awa-organizations');
    } else if (type === ResourceShareEnum.COHORT_GENERAL) {
      return t('common:label.awa-cohorts');
    } else if (type === ResourceShareEnum.COHORT_SESSION) {
      return t('common:label.cohort-session');
    } else {
      return '';
    }
  };

  const getModalName = () => {
    return openModal === ResourceShareEnum.ORGANIZATION_PM || openModal === ResourceShareEnum.ORGANIZATION_ALL
      ? t('common:btn.share-organizations')
      : t('common:btn.share-cohorts');
  };

  const renderShareContent = () => {
    return (
      <div className="b-dropDownMenu">
        <ul>
          <li onClick={() => handleOpenModal(ResourceShareEnum.ORGANIZATION_PM)}>
            {t('main:share.organization-pm')}
          </li>
          <li onClick={() => handleOpenModal(ResourceShareEnum.ORGANIZATION_ALL)}>
            {t('main:share.organization-all')}
          </li>
          {isAllowCohorts && isAllowToShareToCohort ? (
            <>
              <li onClick={() => handleOpenModal(ResourceShareEnum.COHORT_GENERAL)}>
                {t('main:share.cohort-general')}
              </li>
              <li onClick={() => handleOpenModal(ResourceShareEnum.COHORT_SESSION)}>
                {t('main:share.cohort-session')}
              </li>
            </>
          ) : null}
        </ul>
      </div>
    );
  };

  const renderSelectCohort = () => {
    return (
      <div className="b-modal__buttons">
        <AsyncSelect
          placeholder={t('common:label.awa-cohort')}
          handleChange={value => {
            setShareSelectedCohort(value);
            loadCohortOptions();
          }}
          value={shareSelectedCohort}
          defaultOptions={defaultCohortOptions}
          isSearchable
          onMenuOpen={loadCohortOptions}
          loadOptions={(inputValue, callback) => loadCohortOptions(inputValue, callback)}
          ariaLabel={`share-cohort-${openModal}`}
        />
      </div>
    );
  };

  const isShowMainSelect = openModal !== ResourceShareEnum.COHORT_SESSION || shareSelectedCohort;

  const getTooltipShare = (): string => {
    if (type === 'card') {
      return !selectedEntitiesIds.length
        ? t('common:tooltip.share-disable')
        : t('common:tooltip.click-share');
    } else if (type === 'table') {
      return !selectedEntitiesIds.length
        ? t('common:tooltip.share-disable')
        : t('common:tooltip.click-share');
    }
    return '';
  };

  return (
    <>
      <SimpleTooltip id={`share-${data[0]?.id}`} title={getTooltipShare()}>
        <Popover
          isOpen={isSharePopover}
          positions={['bottom']} // preferred positions by priority
          content={renderShareContent}
          reposition={false}
          containerClassName={'popover'}
          align={'end'}
          onClickOutside={() => setIsSharePopover(false)}
        >
          <button
            onClick={() => setIsSharePopover(true)}
            disabled={!selectedEntitiesIds.length}
            className={`b-tableTool -noIcon -mobView -mr`}
          >
            {customHandleButton ? (
              customHandleButton
            ) : (
              <>
                <span style={{ marginRight: 5 }} className="b-button-icon -noHover -share" />
                {t('common:btn.share')}
              </>
            )}
          </button>
        </Popover>
        {openModal ? (
          <Modal
            classNameBack={'b-modal__fixed'}
            showCloseBtn
            title={getModalName()}
            onClose={closeModal}
            className={`b-modal__main`}
          >
            <div className={`b-modal`}>
              <div className={`b-elmAddUserModal__list modal__content`}>
                {openModal === ResourceShareEnum.COHORT_SESSION ? renderSelectCohort() : null}
                {isShowMainSelect && (
                  <div className="b-modal__buttons">
                    <AsyncSelect
                      placeholder={getPlaceholderName(openModal)}
                      handleChange={handleChangeTempSelect}
                      value={tempSelect}
                      defaultOptions={defaultShareOptions}
                      isMulti
                      isSearchable
                      onMenuOpen={loadOptions}
                      loadOptions={(inputValue, callback) => loadOptions(inputValue, callback)}
                      ariaLabel={`share-${openModal}`}
                    />
                  </div>
                )}
              </div>
              <div className="b-modal__buttons">
                <Button
                  title={t('common:btn.cancel')}
                  type="transparency"
                  size="large"
                  onPress={closeModal}
                  className="button-mh"
                />
                <Button
                  title={t('common:btn.save')}
                  size="large"
                  onPress={handleShare}
                  className="button-mh"
                  disabled={!(tempSelect || []).length}
                />
              </div>
            </div>
          </Modal>
        ) : null}
      </SimpleTooltip>
    </>
  );
};

export default ResourceShare;
