import { ActionModel, CalendarDetailsModel, PaymentDetailsModel, ScheduleDateModel } from '../../models';
import { actionCreator } from '../actionCreator';
import { ScheduleAlias } from '../../constants';
import { LOGOUT_SUCCESS } from '../authData/authDataActions';

export const GET_SCHEDULE_CALENDAR = 'GET_SCHEDULE_CALENDAR';

export interface GetScheduleCalendarPayload {
  data: PaymentIntentModel;
  callback?: (res: ScheduleDateModel[], alias: ScheduleAlias) => void;
}
export const getScheduleCalendar = actionCreator<GetScheduleCalendarPayload>(GET_SCHEDULE_CALENDAR);

interface PaymentIntentModel {
  entity_id: number;
  alias: string;
}

interface PaymentConfirmModel {
  front_response_data: any;
}

export const SCHEDULE_PAYMENT_INTENT = 'SCHEDULE_PAYMENT_INTENT';
export interface SchedulePaymentIntentPayload {
  callback?: (res: any) => void;
  data: PaymentIntentModel;
}
export const schedulePaymentIntent = actionCreator<SchedulePaymentIntentPayload>(SCHEDULE_PAYMENT_INTENT);

export const SCHEDULE_PAYMENT_CONFIRM = 'SCHEDULE_PAYMENT_CONFIRM';
export interface SchedulePaymentConfirmPayload {
  callback?: (res: any) => void;
  data: PaymentConfirmModel;
}
export const schedulePaymentConfirm = actionCreator<SchedulePaymentConfirmPayload>(SCHEDULE_PAYMENT_CONFIRM);

interface CreateScheduleModel {
  entity_id: number;
  book_date: string;
  book_time: string;
  book_timezone: string;
  alias: ScheduleAlias;
}

export const CREATE_SCHEDULE = 'CREATE_SCHEDULE';
export interface CreateSchedulePayload {
  callback?: (res: any, error?: null | any) => void;
  data: CreateScheduleModel;
}
export const createSchedule = actionCreator<CreateSchedulePayload>(CREATE_SCHEDULE);

export const GET_SCHEDULE_DATE_DETAILS = 'GET_SCHEDULE_DATE_DETAILS';
export interface GetScheduleDateDetailsPayload {
  callback?: (res: CalendarDetailsModel) => void;
  data: PaymentIntentModel;
}
export const getScheduleDateDetails = actionCreator<GetScheduleDateDetailsPayload>(GET_SCHEDULE_DATE_DETAILS);

export const GET_SCHEDULE_OPTIONS = 'GET_SCHEDULE_OPTIONS';
export const GET_SCHEDULE_OPTIONS_SUCCESS = 'GET_SCHEDULE_OPTIONS_SUCCESS';
export interface GetScheduleOptionsPayload {
  callback?: (res: PaymentDetailsModel) => void;
}
export const getScheduleOptions = actionCreator<GetScheduleOptionsPayload>(GET_SCHEDULE_OPTIONS);
export const getScheduleOptionsSuccess = actionCreator<GetScheduleOptionsPayload>(
  GET_SCHEDULE_OPTIONS_SUCCESS,
);

export type PaymentActionsTypes =
  | ActionModel<typeof LOGOUT_SUCCESS>
  | ActionModel<typeof GET_SCHEDULE_CALENDAR, GetScheduleCalendarPayload>
  | ActionModel<typeof SCHEDULE_PAYMENT_INTENT, SchedulePaymentIntentPayload>
  | ActionModel<typeof CREATE_SCHEDULE, CreateSchedulePayload>
  | ActionModel<typeof GET_SCHEDULE_OPTIONS, GetScheduleOptionsPayload>
  | ActionModel<typeof GET_SCHEDULE_DATE_DETAILS, GetScheduleDateDetailsPayload>
  | ActionModel<typeof GET_SCHEDULE_OPTIONS_SUCCESS, PaymentDetailsModel>;
