import React from 'react';
import { SortDirection, useRowSelection } from 'react-data-grid';
import { BelOrgModel, ColumnType, TableDataEntities } from '../../../../../models';
import { useHistory } from 'react-router';
import { ROOTS } from '../../../../../routes/routes';
import { basicDateFormat, cutString } from '../../../../../helpers';
import { t } from 'i18next';
import useHasAccess from '../../../../../hooks/beloved/adminTool/user-has-access';
import { BelovedPermissions } from '../../../../../constants';
import { Checkbox, ComaList } from '../../../../controls';
import { TableDateFilter } from '../../../../common';
import { useTableDataV2 } from '../../../../../hooks/common/use-table-data-v2';

const getArrClass = (direction: SortDirection | undefined) => {
  if (direction === 'DESC') return '-reverse';
  if (!direction) return '-hide';
  return '';
};

export const getColumns = (): ColumnType<BelOrgModel>[] => [
  {
    key: 'organization_name',
    name: t('common:column.organization-name'),
    width: 170,
    sortable: true,
    editable: false,
    resizable: true,
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'affiliation',
    name: t('common:column.affiliation'),
    sortable: true,
    editable: false,
    resizable: true,
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'org_type',
    name: t('common:column.org-type'),
    sortable: true,
    editable: false,
    resizable: true,
    headerCellClass: 'b-rdgTable__headerCell',
    formatter(props) {
      return <>{props.row.type}</>;
    },
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'project_manager',
    name: t('common:column.project-manager'),
    sortable: true,
    editable: false,
    resizable: true,
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'joined',
    name: t('common:column.joined'),
    sortable: true,
    editable: false,
    resizable: true,
    headerCellClass: 'b-rdgTable__headerCell',
    formatter(props) {
      return <>{props.row.joined ? basicDateFormat(new Date(props.row.joined)) : t('common:column.n-a')}</>;
    },
    headerRenderer(props) {
      const { handleSelectDateFilter, query } = useTableDataV2<BelOrgModel>({
        baseUrl: 'beloved/organizations/',
        entityName: TableDataEntities.ORGANIZATIONS,
        enableSelectedEntities: true,
      });
      if (query.dateFilters) {
        return (
          <TableDateFilter
            dateFilterId={'joined'}
            selectedFilters={query.dateFilters['joined']}
            periodFieldName={'joined_period'}
            dateRangeFieldName={'joined_date_range'}
            onDateFilterChanged={handleSelectDateFilter}
            rdgProps={props}
            fiscalOption={true}
          />
        );
      }
    },
  },
  {
    key: 'last_audit',
    name: t('common:column.last-audit'),
    width: 90,
    sortable: true,
    editable: false,
    resizable: true,
    formatter(props) {
      return (
        <>{props.row.last_audit ? basicDateFormat(new Date(props.row.last_audit)) : t('common:column.n-a')}</>
      );
    },
    headerRenderer(props) {
      const { handleSelectDateFilter, query } = useTableDataV2<BelOrgModel>({
        baseUrl: 'beloved/organizations/',
        entityName: TableDataEntities.ORGANIZATIONS,
        enableSelectedEntities: true,
      });
      if (query.dateFilters) {
        return (
          <TableDateFilter
            dateFilterId={'last_audit'}
            selectedFilters={query.dateFilters['last_audit']}
            periodFieldName={'last_audit_period'}
            dateRangeFieldName={'last_audit_date_range'}
            onDateFilterChanged={handleSelectDateFilter}
            rdgProps={props}
            fiscalOption={true}
          />
        );
      }
    },
  },
  {
    key: 'has_credit',
    name: t('common:label.credits'),
    width: 90,
    sortable: true,
    editable: false,
    resizable: true,
    isShow: true,
    formatter(props) {
      const history = useHistory();
      const navToCredits = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        history.push(ROOTS.BELOVED_CREDITS.replace(':orgId', `${props.row.id}`));
      };

      if (!props.row.has_credit_bool) {
        return <>{props.row.has_credit}</>;
      }

      return (
        <a href="#" onClick={navToCredits} className="b-link">
          {props.row.has_credit}
        </a>
      );
    },

    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'pm_phone',
    name: t('common:label.pm-phone'),
    width: 90,
    sortable: true,
    editable: false,
    resizable: true,
    isShow: false,
    formatter(props) {
      return <>{props.row.pm_phone}</>;
    },
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'address',
    name: t('common:label.address'),
    width: 90,
    sortable: true,
    editable: false,
    resizable: true,
    isShow: false,
    formatter(props) {
      return <>{props.row.address?.full_address || t('common:column.n-a')}</>;
    },
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'legacy_audit',
    name: t('common:label.legacy-audit-organization'),
    width: 90,
    sortable: true,
    editable: false,
    resizable: true,
    isShow: false,
    formatter(props) {
      if (!props.row.legacy_audit) {
        return null;
      }
      return (
        <div className="d-flex justify-content-center h-100 align-items-center">
          <div className="b-button-icon -checkmark" />
        </div>
      );
    },
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'website',
    name: t('common:label.website'),
    width: 40,
    sortable: true,
    editable: false,
    resizable: true,
    isShow: false,
    formatter(props) {
      const handleClick = (e: any) => {
        e.stopPropagation();
      };
      return (
        <>
          {props.row.website ? (
            <a
              onClick={handleClick}
              className="b-link"
              target="_blank"
              rel="noreferrer"
              href={`//${props.row.website}`}
            >
              {props.row.website}
            </a>
          ) : (
            t('common:column.n-a')
          )}
        </>
      );
    },
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {props.column.name}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
  },
  {
    key: 'beloved_team',
    name: t('common:column.beloved-team'),
    sortable: false,
    resizable: true,
    editable: false,
    width: 190,
    isShow: false,
    headerCellClass: 'b-rdgTable__headerCell',
    headerRenderer(props) {
      return (
        <div onClick={() => props.onSort(false)} className="b-rdgTable__headerCell -clickable">
          {t('common:column.assigned-beloved-team')}
          <span className={`b-tableArrow ${getArrClass(props.sortDirection)}`} />
        </div>
      );
    },
    formatter(props) {
      const history = useHistory();
      const handleClick = (e: any, index: number) => {
        e.stopPropagation();
        e.preventDefault();
        history.push(ROOTS.BELOVED_USER_PROFILE.replace(':id', `${props.row.beloved_team[index].value}`));
      };
      return (
        <div className="h-100 d-flex justify-content-between align-items-center">
          <div className="b-adminToolUsers__statusCell">
            <ComaList
              tag={'a'}
              uKey="b-l-"
              handleClick={handleClick}
              nullText={t('common:column.n-a')}
              arr={
                props.row.beloved_team?.length
                  ? props.row.beloved_team.slice(0, 3).map(user => ({ title: cutString(user.label, 20) }))
                  : []
              }
            />
          </div>
        </div>
      );
    },
  },
  {
    key: 'tools',
    name: '',
    disableDrag: true,
    width: 40,
    maxWidth: 40,
  },
  {
    key: 'select',
    name: '',
    disableDrag: true,
    width: 40,
    maxWidth: 40,
    headerRenderer(props) {
      return (
        <Checkbox
          className={`b-equityAudits__checkbox-header`}
          checked={props.allRowsSelected}
          onChange={e => props.onAllRowsSelectionChange(e.target.checked)}
        />
      );
    },
    formatter: props => {
      const history = useHistory();
      const hasAccessToEa = useHasAccess(BelovedPermissions.EA_LIST);
      const hasAccessToDebrief = useHasAccess(BelovedPermissions.DEBRIEF_LIST);
      const [isRowSelected, onRowSelectionChange] = useRowSelection();

      const navToEa = (e: any) => {
        e.stopPropagation();
        history.push(ROOTS.BELOVED_EQUITY_AUDITS, {
          org: {
            id: props.row.id,
            name: props.row.organization_name,
          },
        });
      };

      const navToDebriefsList = (e: any) => {
        e.stopPropagation();
        history.push(ROOTS.BELOVED_DEBRIEFS, {
          org: {
            id: props.row.id,
            name: props.row.organization_name,
          },
        });
      };

      return (
        <div className="b-rdgTable__cellEnd">
          {props.row.has_ea && hasAccessToEa && (
            <button className="b-button-icon -colorNavy -mh2  view" onClick={navToEa} aria-label="View" />
          )}
          {props.row.has_debrief && hasAccessToDebrief && (
            <button
              onClick={navToDebriefsList}
              className="b-button-icon -colorNavy -mh2  edit"
              aria-label="Edit"
            />
          )}
          <div className={'checkBox b-equityAudits__checkbox'} onClick={e => e.stopPropagation()}>
            <Checkbox
              checked={isRowSelected}
              onChange={e => {
                onRowSelectionChange({
                  row: props.row,
                  checked: e.target.checked,
                  isShiftClick: false,
                });
              }}
            />
          </div>
        </div>
      );
    },
  },
];
