import { call, all, take, StrictEffect, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  GET_SCHEDULE_CALENDAR,
  GetScheduleCalendarPayload,
  CreateSchedulePayload,
  CREATE_SCHEDULE,
  GET_SCHEDULE_OPTIONS,
  GetScheduleOptionsPayload,
  getScheduleOptionsSuccess,
  SCHEDULE_PAYMENT_INTENT,
  SchedulePaymentIntentPayload,
  SCHEDULE_PAYMENT_CONFIRM,
  SchedulePaymentConfirmPayload,
  GET_SCHEDULE_DATE_DETAILS,
  GetScheduleDateDetailsPayload,
} from './paymentsActions';
import Api from '../../services/api';
import { getOrgIdFormRoute } from '../../helpers';
import { t } from 'i18next';

function* getScheduleCalendarSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, data },
    }: { payload: GetScheduleCalendarPayload } = yield take(GET_SCHEDULE_CALENDAR);
    try {
      const organization_id = getOrgIdFormRoute();
      const response = yield call(Api.post, `scheduler/${organization_id}/calendar/`, data);
      if (response) {
        callback && callback(response.resource, response.alias);
      }
    } catch (e) {
      toast.error(t('common:toast.error'));
    }
  }
}

function* schedulePaymentIntentSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, data },
    }: { payload: SchedulePaymentIntentPayload } = yield take(SCHEDULE_PAYMENT_INTENT);
    try {
      const organization_id = getOrgIdFormRoute();
      const response = yield call(Api.post, `scheduler/${organization_id}/prepare_payment/`, data);

      if (response) {
        callback && callback(response);
      }
    } catch (e) {
      toast.error(t('common:toast.error'));
    }
  }
}

function* schedulePaymentConfirmSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, data },
    }: { payload: SchedulePaymentConfirmPayload } = yield take(SCHEDULE_PAYMENT_CONFIRM);
    try {
      const organization_id = getOrgIdFormRoute();
      const response = yield call(Api.post, `scheduler/${organization_id}/confirm_payment/`, data);

      if (response) {
        callback && callback(response);
      }
    } catch (e) {
      toast.error(t('common:toast.error'));
    }
  }
}

function* createScheduleSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, data },
    }: { payload: CreateSchedulePayload } = yield take(CREATE_SCHEDULE);
    try {
      const organization_id = getOrgIdFormRoute();
      const response = yield call(Api.post, `scheduler/${organization_id}/schedule/`, data);

      if (response) {
        callback && callback(response, null);
      }
    } catch (e: any) {
      callback && callback(null, e.response.data);
    }
  }
}

function* getScheduleOptionsSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback },
    }: { payload: GetScheduleOptionsPayload } = yield take(GET_SCHEDULE_OPTIONS);
    try {
      const response = yield call(Api.get, `utils/app_payment_details/`);

      if (response) {
        callback && callback(response);
        yield put(getScheduleOptionsSuccess(response));
      }
    } catch (e) {
      toast.error(t('common:toast.error'));
    }
  }
}

function* getScheduleDateDetailsSaga(): Generator<StrictEffect, void, any> {
  while (true) {
    const {
      payload: { callback, data },
    }: { payload: GetScheduleDateDetailsPayload } = yield take(GET_SCHEDULE_DATE_DETAILS);
    try {
      const organization_id = getOrgIdFormRoute();
      const response = yield call(Api.post, `scheduler/${organization_id}/calendar_date_details/`, data);

      if (response) {
        callback && callback(response);
      }
    } catch (e) {
      toast.error(t('common:toast.error'));
    }
  }
}

export function* saga() {
  yield all([
    getScheduleCalendarSaga(),
    schedulePaymentIntentSaga(),
    createScheduleSaga(),
    getScheduleOptionsSaga(),
    schedulePaymentConfirmSaga(),
    getScheduleDateDetailsSaga(),
  ]);
}
