import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { isMobileOnly } from 'react-device-detect';
import styles from './Sidebar.module.scss';
import { Image, SVG, ImageSecured, Link } from '../../controls';
import Logo from '../../../assets/images/logo-awa-horizontal.svg';
import LogoMobile from '../../../assets/images/logo-awa-mobile.svg';
import { StateType } from '../../../store/reducers';
import { logoutRequest } from '../../../store/authData/authDataActions';
import { ROOTS } from '../../../routes/routes';
import { getOrganizationDetails } from '../../../store/organization/organizationActions';
import {
  KeyValueNumberModel,
  OrganizationDetailsModel,
  OrganizationRoleTreeModel,
  QueryParamsModel,
  TableDataEntities,
} from '../../../models';
import { useRole } from '../../../helpers/roles';
import { setQuery as debriefSetQuery } from '../../../store/beloved/debriefs/debriefsActions';
import { useTranslation } from 'react-i18next';
import { getCurrentYear } from '../../../helpers';
import { DOMAIN_URI, BelovedPermissions } from '../../../constants';
import useHasAccess from '../../../hooks/beloved/adminTool/user-has-access';
import { setTableItem } from '../../../store/table/tableReducer';

const Sidebar: FC = () => {
  const { t } = useTranslation();
  const user = useSelector((state: StateType) => state.profile.authUser);
  const currentOrg: OrganizationDetailsModel | null = useSelector(
    (state: StateType) => state.organization.currentOrg,
  );
  const orgLoading: boolean = useSelector((state: StateType) => state.organization.orgLoading);
  const defaultsOrgTab: KeyValueNumberModel<ROOTS[keyof ROOTS]> = useSelector(
    (state: StateType) => state.organization.defaultsOrgTab,
  );
  const infoList = useSelector((state: StateType) => state.utils.info);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const isAllowToCredits = useHasAccess(BelovedPermissions.CREDITS_LIST);
  const [isOpen, setIsOpen] = useState(false);
  let [localMobile, setLocalMobile] = useState(window.innerWidth <= 1023);
  const reportCounter: number = useSelector((state: StateType) => state.report.gaData.ea_report_counter);
  const access = useRole();
  const isBeloved = user?.beloved_user;

  const _getOrgId = () => {
    const comps = location.pathname.split('/');
    if (comps[1] === 'org' && Number(comps[2])) {
      return Number(comps[2]);
    }
  };

  const orgId = _getOrgId();

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth <= 1023) {
        setLocalMobile(true);
      } else {
        setLocalMobile(false);
      }
    });
  }, []);

  const getOrgDetailsCallback = (res?: OrganizationDetailsModel, error?: any) => {
    if (error) {
      history.push(ROOTS.HOME_ORG);
    }
  };

  useEffect(() => {
    if (orgId && !orgLoading && user?.orgs_tree) {
      if (!currentOrg || currentOrg.id !== orgId) {
        dispatch(
          getOrganizationDetails({
            orgId: Number(orgId),
            callback: getOrgDetailsCallback,
          }),
        );
      }
    }
  }, [orgId, currentOrg]);

  if (!(user && user.user)) return null;

  const handleLogout = () => {
    dispatch(
      logoutRequest({
        callback: () => {
          history.push(ROOTS.SIGNIN);
        },
      }),
    );
  };

  const navToOrg = (org: OrganizationRoleTreeModel) => () => {
    setIsOpen(false);
    const showEA = access(['project_manager', 'audit_contributor'], org.user_role_id);
    const showELM = access(['employee_staff'], org.user_role_id);
    dispatch(
      getOrganizationDetails({
        orgId: Number(org.org_id),
        callback: (res, err) => {
          getOrgDetailsCallback(res, err);
          if (showEA) {
            history.push(
              `${defaultsOrgTab[org.org_id] || ROOTS.EQUITY_AUDIT_LIST}`.replace(':orgId', `${org.org_id}`),
            );
            return;
          }
          if (showELM) {
            history.push(
              `${defaultsOrgTab[org.org_id] || ROOTS.ELM_LIST}`.replace(':orgId', `${org.org_id}`),
            );
            return;
          }
          history.push(
            `${defaultsOrgTab[org.org_id] || ROOTS.ORG_PROFILE}`.replace(':orgId', `${org.org_id}`),
          );
        },
      }),
    );
  };

  const resetBelovedParams = (route: string) => {
    const params: QueryParamsModel = {
      sorting: null,
      search: '',
      page_size: 15,
      page: 1,
      filters: {},
      dateFilters: {},
    };
    switch (route) {
      case ROOTS.BELOVED_DEBRIEFS:
        dispatch(
          debriefSetQuery({
            sorting: { columnKey: 'debrief_scheduled_date', direction: 'DESC' },
            search: '',
            page_size: 15,
            page: 1,
            filters: {},
            dateFilters: {},
          }),
        );
        break;
      case ROOTS.BELOVED_EQUITY_AUDITS:
        dispatch(
          setTableItem({
            id: TableDataEntities.EA,
            tableItem: {
              query: params,
            },
          }),
        );
        break;
      case ROOTS.BELOVED_ELM_LIST:
        dispatch(
          setTableItem({
            id: TableDataEntities.ELM,
            tableItem: {
              query: params,
            },
          }),
        );
        break;
      case ROOTS.BELOVED_ORGANIZATIONS:
        dispatch(
          setTableItem({
            id: TableDataEntities.ORGANIZATIONS,
            tableItem: {
              query: params,
            },
          }),
        );
        break;
    }
  };

  const navigateBelovedApp = (route: string) => () => {
    setIsOpen(false);
    resetBelovedParams(route);
    history.push(route);
  };

  const navTo = (route: string, params?: any) => () => {
    setIsOpen(false);
    history.push(route, params);
  };

  const navToGA = () => {
    if (isMobileOnly || reportCounter <= 1) {
      return;
    }
    setIsOpen(false);
    history.push(ROOTS.GROUP_ANALYSIS.replace(':orgId', `my-organizations`));
  };

  const renderTree = (orgs: OrganizationRoleTreeModel[], padding: number): ReactNode => {
    return orgs.map(org => {
      return (
        <ul key={`org-${org.org_id}`}>
          <li>
            <div style={{ paddingLeft: padding }}>
              <Link
                className={`${styles.Menu__subItem} ${
                  org.org_id === Number(orgId) ? styles.Menu__subItem_active : ''
                }`}
                onClick={navToOrg(org)}
              >
                <span className={styles.Menu__text}>{org.org_name}</span>
              </Link>
            </div>
            {org.sub_orgs.length ? renderTree(org.sub_orgs, padding + 20) : null}
          </li>
        </ul>
      );
    });
  };

  const renderBelovedMenu = () => {
    if (!user.beloved_user) return null;

    const isDashboard = location.pathname.split('/').includes('dashboard');
    const isOrganizations = location.pathname.split('/').includes('organizations');
    const isAdminTool = location.pathname.match('admin-tool');
    const isMessages = location.pathname.match('message');
    const isCohort = location.pathname.match('cohort');
    const isEa = location.pathname.split('/').includes('equity-audits');
    const isElm = location.pathname.split('/').includes('elms');
    const isEwp = location.pathname.split('/').includes('ewp');
    const isDebrief = location.pathname.split('/').includes('view-debrief');
    const isCredits = location.pathname.match('credits');

    const isBelovedTeam = location.pathname.match('beloved-user');
    const isOrgAssignmentList = location.pathname.match('org-assignment');
    const isResource = location.pathname.match('resource');

    const isAllowEa = user.beloved_user.permissions.includes(BelovedPermissions.EA_LIST);
    const isAllowDebrief = user.beloved_user.permissions.includes(BelovedPermissions.DEBRIEF_LIST);
    const isAllowOrgs = user.beloved_user.permissions.includes(BelovedPermissions.ORGANIZATIONS_LIST);
    const isAllowElm = user.beloved_user.permissions.includes(BelovedPermissions.ELM_LIST);
    const isAllowResources = user.beloved_user.permissions.includes(BelovedPermissions.RESOURCE_LIBRARY_LIST);
    const isAllowBelovedTeam = user.beloved_user.permissions.includes(BelovedPermissions.BELOVED_TEAM);
    const isAllowBelovedOrgAssignment = user.beloved_user.permissions.includes(
      BelovedPermissions.BELOVED_ORG_ASSIGNMENT,
    );
    const isAllowAdUsers = user.beloved_user.permissions.includes(BelovedPermissions.AD_BELOVED_USERS);
    const isAllowAdRoles = user.beloved_user.permissions.includes(BelovedPermissions.ROLES_AND_FUNCTIONALITY);
    const isAllowCohorts = user.beloved_user.permissions.includes(BelovedPermissions.COHORT_LIST);
    const isAllowMarketingCom = user.beloved_user.permissions.includes(
      BelovedPermissions.MARKETING_COMMUNICATION_VIEW,
    );
    const isAllowEwp = user.beloved_user.permissions.includes(BelovedPermissions.EWP_LIST);

    return (
      <ul className={styles.Menu}>
        <li>
          <Link
            onClick={navTo(ROOTS.BELOVED_DASHBOARD_EA)}
            className={`${styles.Menu__item} ${isDashboard ? styles.Menu__item_active : ''}`}
          >
            <div className={`${styles.Menu__ic} ${styles.Menu__ic_dashboard}`} />
            <span className={styles.Menu__text}>{t('common:tabs.dashboard')}</span>
          </Link>
        </li>
        {user.beloved_user.show_tasks ? (
          <li>
            <Link
              onClick={navTo(ROOTS.MY_TASKS)}
              className={`${styles.Menu__item} ${
                location.pathname === ROOTS.MY_TASKS ? styles.Menu__item_active : ''
              }`}
            >
              <div className={`${styles.Menu__ic} ${styles.Menu__ic_myprojects}`} />
              <span className={styles.Menu__text}>{t('common:tabs.my-tasks')}</span>
            </Link>
          </li>
        ) : null}
        {user.orgs_tree ? (
          <>
            <li className={`${styles.Menu__parentItem}`}>
              <Link
                tabIndex={0}
                onClick={navToGA}
                className={`
                ${styles.Menu__parentItemTitle}
                ${
                  location.pathname === ROOTS.GROUP_ANALYSIS.replace(':orgId', `my-organizations`)
                    ? styles.Menu__item_active
                    : ''
                }`}
              >
                <div className={`${styles.Menu__ic} ${styles.Menu__ic_group}`} />
                <span className={styles.Menu__text}>{t('common:tabs.my-organizations')}</span>
              </Link>
              {user.orgs_tree?.length ? renderTree(user.orgs_tree, 0) : null}
            </li>
          </>
        ) : null}
        {isAllowOrgs ? (
          <li>
            <Link
              onClick={navigateBelovedApp(ROOTS.BELOVED_ORGANIZATIONS)}
              className={`${styles.Menu__item} ${isOrganizations ? styles.Menu__item_active : ''}`}
            >
              <div className={`${styles.Menu__ic} ${styles.Menu__ic_group}`} />
              <span className={styles.Menu__text}>{t('common:tabs.organizations')}</span>
            </Link>
          </li>
        ) : null}
        {isAllowDebrief || isAllowCohorts || isAllowEwp ? (
          <li className={styles.Menu__parentItem}>
            <div className={`${styles.Menu__parentItemTitle} ${styles.Menu__parentItemTitle_noHover}`}>
              <div className={`${styles.Menu__ic} ${styles.Menu__ic_projects}`} />
              <span className={styles.Menu__text}>{t('common:tabs.projects')}</span>
            </div>
            <ul>
              {isAllowDebrief ? (
                <li>
                  <Link
                    className={`${styles.Menu__subItem} ${
                      location.pathname === ROOTS.BELOVED_DEBRIEFS || isDebrief
                        ? styles.Menu__item_active
                        : ''
                    }`}
                    onClick={navigateBelovedApp(ROOTS.BELOVED_DEBRIEFS)}
                  >
                    <span className={styles.Menu__text}>{t('common:tabs.debriefs')}</span>
                  </Link>
                </li>
              ) : null}
              {isAllowCohorts ? (
                <li>
                  <Link
                    className={`${styles.Menu__subItem} ${isCohort ? styles.Menu__item_active : ''}`}
                    onClick={navigateBelovedApp(ROOTS.BELOVED_COHORT_LIST)}
                  >
                    <span className={styles.Menu__text}>{t('common:tabs.cohorts')}</span>
                  </Link>
                </li>
              ) : null}
              {isAllowEwp ? (
                <li>
                  <Link
                    className={`${styles.Menu__subItem} ${isEwp ? styles.Menu__item_active : ''}`}
                    onClick={navigateBelovedApp(ROOTS.BELOVED_EWP_LIST)}
                  >
                    <span className={styles.Menu__text}>{t('common:tabs.equity-work-plans')}</span>
                  </Link>
                </li>
              ) : null}
            </ul>
          </li>
        ) : null}
        {isAllowEa || isAllowElm ? (
          <li className={styles.Menu__parentItem}>
            <div className={`${styles.Menu__parentItemTitle} ${styles.Menu__parentItemTitle_noHover}`}>
              <div className={`${styles.Menu__ic} ${styles.Menu__ic_auditManagement}`} />
              <span className={styles.Menu__text}>{t('common:tabs.audit-management')}</span>
            </div>
            <ul>
              {isAllowEa ? (
                <li>
                  <Link
                    className={`${styles.Menu__subItem} ${isEa ? styles.Menu__item_active : ''}`}
                    onClick={navigateBelovedApp(ROOTS.BELOVED_EQUITY_AUDITS)}
                  >
                    <span className={styles.Menu__text}>{t('common:headlines.ea')}</span>
                  </Link>
                </li>
              ) : null}
              {isAllowElm ? (
                <li>
                  <Link
                    className={`${styles.Menu__subItem} ${isElm ? styles.Menu__item_active : ''}`}
                    onClick={navigateBelovedApp(ROOTS.BELOVED_ELM_LIST)}
                  >
                    <span className={styles.Menu__text}>{t('common:tabs.equity-lens-maps')}</span>
                  </Link>
                </li>
              ) : null}
            </ul>
          </li>
        ) : null}
        {isAllowResources ? (
          <li>
            <Link
              onClick={
                isMobileOnly
                  ? navTo(ROOTS.BELOVED_RESOURCE_LIST_CARDS.replace(':action', `general`))
                  : navTo(ROOTS.BELOVED_RESOURCE_LIST_TABLE.replace(':action', `general`))
              }
              className={`${styles.Menu__item} ${isResource ? styles.Menu__item_active : ''}`}
            >
              <div className={`${styles.Menu__ic} ${styles.Menu__ic_resources}`} />
              <span className={styles.Menu__text}>{t('common:tabs.resource-library')}</span>
            </Link>
          </li>
        ) : null}
        {isAllowBelovedTeam ? (
          <li className={styles.Menu__parentItem}>
            <Link
              onClick={navTo(ROOTS.BELOVED_USER_LIST)}
              className={`${styles.Menu__item} ${isBelovedTeam ? styles.Menu__item_active : ''}`}
            >
              <div className={`${styles.Menu__ic} ${styles.Menu__ic_belovedTeam}`} />
              <span className={styles.Menu__text}>{t('common:column.beloved-team')}</span>
            </Link>
            <ul>
              {isAllowBelovedOrgAssignment ? (
                <li>
                  <Link
                    className={`${styles.Menu__subItem} ${
                      isOrgAssignmentList ? styles.Menu__item_active : ''
                    }`}
                    onClick={navTo(ROOTS.BEL_ORG_ASSIGNMENT_LIST)}
                  >
                    <span className={styles.Menu__text}>{t('common:label.organization-assignments')}</span>
                  </Link>
                </li>
              ) : null}
            </ul>
          </li>
        ) : null}
        {isAllowToCredits ? (
          <li>
            <Link
              onClick={() => {
                navigateBelovedApp(ROOTS.BELOVED_CREDIT_LIST)();
              }}
              className={`${styles.Menu__item} ${isCredits ? styles.Menu__item_active : ''}`}
            >
              <div className={`${styles.Menu__ic} ${styles.Menu__ic_belovedCredit}`} />
              <span className={styles.Menu__text}>{t('common:tabs.beloved-credits')}</span>
            </Link>
          </li>
        ) : null}
        {isAllowMarketingCom ? (
          <li>
            <Link
              onClick={navigateBelovedApp(ROOTS.BELOVED_MESSAGE_LIST)}
              className={`${styles.Menu__item} ${isMessages ? styles.Menu__item_active : ''}`}
            >
              <div className={`${styles.Menu__ic} ${styles.Menu__ic_mail}`} />
              <span className={styles.Menu__text}>{t('common:label.marketing-communication')}</span>
            </Link>
          </li>
        ) : null}
        {isAllowAdRoles || isAllowAdUsers ? (
          <li>
            <Link
              onClick={() => {
                if (isAllowAdUsers) {
                  navigateBelovedApp(ROOTS.BELOVED_ADMIN_TOOL_USERS)();
                } else if (isAllowAdRoles) {
                  navigateBelovedApp(ROOTS.BELOVED_ADMIN_TOOL_ROLES)();
                }
              }}
              className={`${styles.Menu__item} ${isAdminTool ? styles.Menu__item_active : ''}`}
            >
              <div className={`${styles.Menu__ic} ${styles.Menu__ic_adminTool}`} />
              <span className={styles.Menu__text}>{t('common:label.admin-tool')}</span>
            </Link>
          </li>
        ) : null}
      </ul>
    );
  };

  const renderOrgMenu = () => {
    const isResource = location.pathname.match('resource');
    const isBelovedTeam = location.pathname.match('beloved-user');
    const isResourceFavorite = isResource && location.pathname.match('favorite');
    const isResourceRecommend = isResource && location.pathname.match('recommend');
    const isResourceGeneral = isResource && !(isResourceFavorite || isResourceRecommend);

    return (
      <ul className={styles.Menu}>
        <li>
          <Link
            onClick={navTo(ROOTS.HOME_ORG)}
            className={`${styles.Menu__item} ${
              location.pathname === ROOTS.HOME_ORG ? styles.Menu__item_active : ''
            }`}
          >
            <div className={`${styles.Menu__ic} ${styles.Menu__ic_home}`} />
            <span className={styles.Menu__text}>{t('common:tabs.home')}</span>
          </Link>
        </li>
        <li>
          <Link
            onClick={navTo(ROOTS.MY_TASKS)}
            className={`${styles.Menu__item} ${
              location.pathname === ROOTS.MY_TASKS ? styles.Menu__item_active : ''
            }`}
          >
            <div className={`${styles.Menu__ic} ${styles.Menu__ic_myprojects}`} />
            <span className={styles.Menu__text}>{t('common:tabs.my-tasks')}</span>
          </Link>
        </li>
        <li>
          <Link
            onClick={navTo(ROOTS.RESOURCE_LIST_CARDS.replace(':action', 'general'))}
            className={`${styles.Menu__item} ${isResourceGeneral ? styles.Menu__item_active : ''}`}
          >
            <div className={`${styles.Menu__ic} ${styles.Menu__ic_resources}`} />
            <span className={styles.Menu__text}>{t('common:tabs.resource-library')}</span>
          </Link>
          <ul>
            <li>
              <Link
                className={`${styles.Menu__subItem} ${isResourceFavorite ? styles.Menu__item_active : ''}`}
                onClick={navTo(ROOTS.RESOURCE_LIST_CARDS.replace(':action', 'favorite'))}
              >
                <span className={styles.Menu__text}>{t('common:tabs.my-favorites')}</span>
              </Link>
            </li>
            <li>
              <Link
                className={`${styles.Menu__subItem} ${isResourceRecommend ? styles.Menu__item_active : ''}`}
                onClick={navTo(ROOTS.RESOURCE_LIST_CARDS.replace(':action', 'recommend'))}
              >
                <span className={styles.Menu__text}>{t('common:tabs.beloved-recommended')}</span>
              </Link>
            </li>
          </ul>
        </li>
        <li>
          <Link
            onClick={navTo(ROOTS.BELOVED_USER_LIST)}
            className={`${styles.Menu__item} ${isBelovedTeam ? styles.Menu__item_active : ''}`}
          >
            <div className={`${styles.Menu__ic} ${styles.Menu__ic_belovedTeam}`} />
            <span className={styles.Menu__text}>{t('common:column.beloved-team')}</span>
          </Link>
        </li>
        <li className={`${styles.Menu__parentItem}`}>
          <Link
            tabIndex={0}
            onClick={navToGA}
            className={`
                ${styles.Menu__parentItemTitle}
                ${
                  location.pathname === ROOTS.GROUP_ANALYSIS.replace(':orgId', `my-organizations`)
                    ? styles.Menu__item_active
                    : ''
                }`}
          >
            <div className={`${styles.Menu__ic} ${styles.Menu__ic_group}`} />
            <span className={styles.Menu__text}>{t('common:tabs.my-organizations')}</span>
          </Link>
          {user.orgs_tree?.length ? renderTree(user.orgs_tree, 0) : null}
        </li>
      </ul>
    );
  };

  if (isMobileOnly || localMobile) {
    return (
      <div className={`${styles.Header}`}>
        <button type="button" className={styles.navOpener} onClick={() => setIsOpen(!isOpen)}>
          <span></span>
        </button>
        <div className={styles.MobileLogo}>
          <Image source={LogoMobile} width={'36px'} height={'36px'} alt={t('awa:AT9')} />
        </div>
        <Link onClick={navTo(ROOTS.MY_ACCOUNT)} className={'b-avatar'}>
          {user.user.avatar ? (
            <ImageSecured src={`${DOMAIN_URI}${user.user.avatar}`} alt={t('awa:AT46')} />
          ) : (
            `${user.user.first_name[0]}${user.user.last_name[0]}`
          )}
        </Link>
        {isOpen ? (
          <>
            <div className={`${styles.mobileNav}`}>
              <div className={styles.navClose__wrapper}>
                <div className={`${styles.mobileNav_heading}`}>
                  <div className={styles.MobileLogo}>
                    <Image source={LogoMobile} width={'36px'} height={'36px'} alt={t('awa:AT9')} />
                  </div>
                </div>
                <button type="button" className={styles.navClose} onClick={() => setIsOpen(!isOpen)} />
              </div>
              {isBeloved ? renderBelovedMenu() : renderOrgMenu()}
              <div className={`${styles.copy}`}>
                <div>Awa by Beloved {infoList.app_version}</div>
                <div>© {getCurrentYear()}, Beloved Community</div>
              </div>
            </div>
          </>
        ) : null}
      </div>
    );
  }

  return (
    <header className={`${styles.Sidebar}`}>
      <div className={styles.Sidebar_inner}>
        <div className={styles.Logo}>
          <SVG source={Logo} width={'115px'} height={'51px'} />
        </div>
        <div className={styles.UserPanel}>
          <Link onClick={navTo(ROOTS.MY_PROFILE)} className={'b-avatar -mr'}>
            {user.user.avatar ? (
              <ImageSecured src={`${DOMAIN_URI}${user.user.avatar}`} alt={t('awa:AT46')} />
            ) : (
              `${user.user.first_name[0]}${user.user.last_name[0]}`
            )}
          </Link>
          <div>
            <div className={styles.UserPanel__name}>
              <Link onClick={navTo(ROOTS.MY_PROFILE)}>
                {user.user.first_name} {user.user.last_name}
              </Link>
            </div>
            <Link onClick={handleLogout} className={styles.UserPanel__logout}>
              {t('common:btn.log-out')}
            </Link>
          </div>
        </div>
        {isBeloved ? renderBelovedMenu() : renderOrgMenu()}
        <div className={`${styles.copy}`}>
          <div>Awa by Beloved {infoList.app_version}</div>
          <div>© {getCurrentYear()}, Beloved Community</div>
        </div>
      </div>
    </header>
  );
};

export default Sidebar;
