import { BelOrganizationsActionsTypes, BEL_GET_ORGANIZATION_DETAILS_SUCCESS } from './organizationsActions';
import { BelOrgDetailsModel } from '../../../models';
import { LOGOUT_SUCCESS } from '../../authData/authDataActions';

export type BelOrganizationsState = {
  currentBelOrg: BelOrgDetailsModel | null;
};

const initialState: BelOrganizationsState = {
  currentBelOrg: null,
};

const belOrganizationsReducer = (
  state: BelOrganizationsState = initialState,
  action: BelOrganizationsActionsTypes,
): BelOrganizationsState => {
  switch (action.type) {
    case LOGOUT_SUCCESS: {
      return initialState;
    }
    case BEL_GET_ORGANIZATION_DETAILS_SUCCESS: {
      return {
        ...state,
        currentBelOrg: action.payload,
      };
    }
    default:
      return state;
  }
};

export default belOrganizationsReducer;
