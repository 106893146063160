export enum EA_ROLES {
  EA_TEAM = 2,
  MANAGER = 1,
}

export enum UserTypes {
  BELOVED_TEAM = 1,
  INTERNAL = 2,
}

export enum BelovedPermissions {
  ROLES_AND_FUNCTIONALITY = 'roles_and_fuctionality',
  AD_BELOVED_USERS = 'beloved_users',

  ORGANIZATION_PROFILE = 'organizations_profile',
  ORGANIZATIONS_LIST = 'organizations_list',
  ORGANIZATION_PM_PROFILE = 'organization_pm_profile',
  ORGANIZATION_CHANGE_PM = 'organization_change_pm',
  ORGANIZATION_REMOVE_SUG_ORG = 'organization_remove_sub_org',
  ORGANIZATION_REMOVE_ORG = 'organizations_remove_org',

  CREDITS_LIST = 'credits_list',
  CREDITS_UPDATE = 'credits_update',
  CREDITS_CREATE = 'credits_create',

  DEBRIEF_LIST = 'debrief_list',
  DEBRIEF_VIEW_PRESENTATION = 'debrief_view_presentation',
  DEBRIEF_ASSIGN_LEAD = 'debrief_assign_lead',

  EA_LIST = 'ea_list',
  EA_REPORT = 'ea_report',
  EA_REMOVE = 'ea_remove',

  ELM_LIST = 'elm_list',
  ELM_VIEW_REPORT = 'elm_view_report',
  ELM_REMOVE = 'elm_remove',

  RESOURCE_LIBRARY_LIST = 'resource_library_list',
  RESOURCE_LIBRARY_CREATE_UPDATE = 'resource_library_create_update',
  RESOURCE_LIBRARY_REVIEW = 'resource_library_reviewer',

  COHORT_LIST = 'cohort_list',

  EWP_LIST = 'ewp_list',
  EWP_DETAILS = 'ewp_details',
  EWP_CRUD = 'ewp_crud',
  EWP_EDIT_LEADER = 'ewp_edit_leader',

  BELOVED_TEAM = 'beloved_team_list',
  BELOVED_ORG_ASSIGNMENT = 'org_assignments_list',
  BELOVED_ORG_END_ASSIGNMENT = 'assignments_end',
  ASSIGNED_BELOVED_TEAM = 'organization_assigned_beloved_team',

  MARKETING_COMMUNICATION = 'marketing_communication',
  MARKETING_COMMUNICATION_VIEW = 'marketing_communication_view',
  MARKETING_COMMUNICATION_CREATE = 'marketing_communication_create',
  MARKETING_COMMUNICATION_UPDATE = 'marketing_communication_update',
  MARKETING_COMMUNICATION_REMOVE = 'marketing_communication_remove',
}
