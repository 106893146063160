import React from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  AddToCalendar,
  Carousel,
  CohortMessageItem,
  CohortSessionItem,
  HeadlineOrganization,
  NewsBlockItem,
  OrgBreadcrumbs,
} from '../../../../common';
import { useTranslation } from 'react-i18next';
import { useGetOrgCohortDetails } from '../../../../../hooks/cohorts/use-get-org-cohort-details';
import { useGetOrgCohortMessages } from '../../../../../hooks/cohorts/use-get-org-cohort-messages';
import { ImageSecured, Loader } from '../../../../controls';
import { CohortItemModel, CohortModel, IRouteParamsBase } from '../../../../../models';
import { useGetOrgCohortSessions } from '../../../../../hooks/cohorts/use-get-org-cohort-sessions';
import { useGetOrgRoute } from '../../../../../hooks/org/use-get-org-route';
import { ROOTS } from '../../../../../routes/routes';
import { basicDateFormat, getMessagePosition, getSessionPosition } from '../../../../../helpers';
import { useNavBack } from '../../../../../hooks/common/use-nav-back';
import EmptyLink from '../../../../controls/EmptyLink/EmptyLink';
import { DOMAIN_URI } from '../../../../../constants';
import ActivityDeck from '../../../../../assets/images/icons/gslides.svg';

interface IRouteParams extends IRouteParamsBase {
  id: string;
  organizationId: string;
}

const CohortDetails = () => {
  const { t } = useTranslation();
  const { id, orgId, organizationId } = useParams<IRouteParams>();
  const data = useGetOrgCohortDetails(organizationId || orgId);
  const messages = useGetOrgCohortMessages();
  const sessions = useGetOrgCohortSessions();
  const COHORT_ORGS = useGetOrgRoute(ROOTS.COHORT_ORGS);
  const { navBack } = useNavBack();

  const renderList = (list: CohortItemModel[]) => {
    if (list.length > 4) {
      return (
        <>
          {list.slice(0, 4).map(item => {
            return <div key={`org-${item.id}`}>{item.name}</div>;
          })}
          ...
        </>
      );
    }
    return list.map(item => {
      return <div key={`org-${item.id}`}>{item.name}</div>;
    });
  };

  const _convertBelovedTeam = (team: CohortModel['beloved_team']): CohortItemModel[] => {
    return team.map(item => ({
      id: item.user_id,
      name: `${item.first_name} ${item.last_name}`,
    }));
  };

  const renderContent = () => {
    if (!data) return <Loader />;
    return (
      <>
        <div className="b-panelData">
          <div className="d-flex">
            {data.image?.file ? (
              <div className="b-avatar -cohort mr-3">
                <ImageSecured src={`${DOMAIN_URI}${data.image.file}`} alt={t('common:alt.image')} />
              </div>
            ) : null}
            <div>
              <dl className="b-panelData__row mt-2">
                <dt>
                  <div className="b-panelData__fieldTitle">{t('common:label.type')}</div>
                </dt>
                <dd>
                  <div className="b-panelData__fieldValue">{data.type_name}</div>
                </dd>
                <dt />
                <dd />
              </dl>
              <dl className="b-panelData__row">
                <dt>
                  <div className="b-panelData__fieldTitle">{t('common:column.start-date')}</div>
                </dt>
                <dd>
                  <div className="b-panelData__fieldValue">
                    {data.start_date ? basicDateFormat(data.start_date) : null}
                  </div>
                </dd>
                <dt>
                  <div className="b-panelData__fieldTitle">{t('common:column.end-date')}</div>
                </dt>
                <dd>
                  <div className="b-panelData__fieldValue">
                    {data.end_date ? basicDateFormat(data.end_date) : null}
                  </div>
                  <div className="mt-2">
                    <AddToCalendar type={'cohort'} startDate={data.start_date} endDate={data.end_date} />
                  </div>
                </dd>
              </dl>
              {data.activity_deck ? (
                <dl className="b-panelData__row b-cohortDetails__activity">
                  <dt>
                    <div className="b-panelData__fieldTitle">{t('common:column.activity-deck')}</div>
                  </dt>
                  <dd>
                    <div className="b-panelData__fieldValue">
                      <a href={`${data.activity_deck || '#'}`} target="_blank" rel="noreferrer">
                        <img src={ActivityDeck} alt="ActivityDeck" />
                      </a>
                    </div>
                  </dd>
                  <dt />
                  <dd />
                </dl>
              ) : null}
            </div>
          </div>
          <dl className="b-panelData__row">
            <dt>
              <div className="b-panelData__fieldTitle">{t('common:column.beloved-team')}</div>
            </dt>
            <dd>
              <div className="b-panelData__fieldValue">
                {renderList(_convertBelovedTeam(data.beloved_team))}
              </div>
            </dd>
            <dt />
            <dd />
          </dl>
          <Link
            to={ROOTS.COHORT_PARTICIPANT_DETAILS.replace(':id', id)
              .replace(':orgId', `${orgId}`)
              .replace(':organizationId', `${orgId}`)}
            className="orange-light-text font-s d-block mb-2"
          >
            {t('common:btn.organization-participants')}
          </Link>
          <Link to={COHORT_ORGS.replace(':id', id)} className="orange-light-text font-s d-block mb-2">
            {t('common:btn.cohort-organizations')}
          </Link>
        </div>
        <div className="b-cohortDetails__heading">
          <b>{t('common:headlines.sessions')}</b> - {t('main:cohort.session-description')}
        </div>
        {sessions && sessions.length ? (
          <Carousel initPosition={getSessionPosition(sessions)}>
            {sessions.map(item => {
              return <CohortSessionItem key={`session-item-${item.id}`} data={item} />;
            })}
          </Carousel>
        ) : null}
        <div className="b-cohortDetails__heading">
          <b>{t('common:headlines.messages')}</b> - {t('main:cohort.message-description')}
        </div>
        {messages && messages.length ? (
          <Carousel initPosition={getMessagePosition(messages)}>
            {messages.map(item => {
              return <CohortMessageItem key={`message-item-${item.id}`} data={item} />;
            })}
          </Carousel>
        ) : null}
        <div className="b-cohortDetails__heading">
          <b>{t('common:headlines.additional-resources')}</b> - {t('main:cohort.general-description')}
        </div>
        {data.resources.length ? (
          <Carousel>
            {data.resources.map(item => {
              return <NewsBlockItem key={`news-item-${item.id}`} data={item} />;
            })}
          </Carousel>
        ) : null}
      </>
    );
  };

  return (
    <main className="b-page">
      <div className="b-page__header -desktop">
        <HeadlineOrganization
          orgName={data?.name || ''}
          orgId={data?.id || ''}
          headline={t('common:headlines.cohort-program')}
          customClasses={'-noDesktopMb mr-4'}
          backBtn={<EmptyLink onClick={navBack} className="b-button-icon-back" />}
          noOrgLogo
        />
      </div>
      <OrgBreadcrumbs />
      <div className="contentContainer">{renderContent()}</div>
    </main>
  );
};

export default CohortDetails;
