export const eaStatusList = {
  0: { id: 0, title: 'In Preparation' },
  1: { id: 1, title: 'Preparation Completed' },
  2: { id: 2, title: 'In Progress' },
  3: { id: 3, title: 'Completed' },
};

export enum eaAVgListType {
  SELECTED = 'selected',
}
