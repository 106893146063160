import React, { FC } from 'react';
import { Button } from '../../../../../controls';
import { InstructionStep, IRouteParamsBase } from '../../../../../../models';
import { useHistory, useParams } from 'react-router';
import { ROOTS } from '../../../../../../routes/routes';
import { useTranslation } from 'react-i18next';

interface IProps {
  setCurrentStep: (step: InstructionStep) => void;
}

const Intro2: FC<IProps> = ({ setCurrentStep }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { orgId } = useParams<IRouteParamsBase>();

  const handleCreate = () => {
    history.push(ROOTS.CREATE_EA.replace(':orgId', orgId));
  };

  return (
    <div className={'b-step__container -centerContent'}>
      <h1 className={'b-step__bigTitle'}>{t('awa:M27.title')}</h1>
      <div className="b-step__p b-equityAudit__textCenter">
        {t('awa:M27.msg.p1.part1')} <span className={'b-step__orangeBold'}>{t('awa:M27.msg.p1.part2')}</span>{' '}
        {t('awa:M27.msg.p1.part3')}
      </div>
      <div className="b-step__wBox" style={{ maxWidth: '362px' }}>
        <div className="b-step__numItem">
          <div className="b-step__num">1</div>
          <div className="paragraph">{t('awa:M27.msg.p2')}</div>
        </div>
        <div className="b-step__numItem">
          <div className="b-step__num">2</div>
          <div className="paragraph">{t('awa:M27.msg.p3')}</div>
        </div>
        <div className="b-step__numItem">
          <div className="b-step__num">3</div>
          <div className="paragraph">{t('awa:M27.msg.p4')}</div>
        </div>
        <div className="b-step__numItem">
          <div className="b-step__num">4</div>
          <div className="paragraph">{t('awa:M27.msg.p5')}</div>
        </div>
        <div className="b-step__numItem">
          <div className="b-step__num">5</div>
          <div className="paragraph">{t('awa:M27.msg.p6')}</div>
        </div>
      </div>
      <div className="b-step__p b-equityAudit__textCenter">
        {t('awa:M27.msg.p7.part1')} <b>{t('awa:M27.msg.p7.part2')}</b>.
      </div>
      <div className="b-step__btns">
        <Button
          className={'btn -type_form b-step__mr8'}
          type={'bordered'}
          onPress={() => setCurrentStep('step1')}
          title={t('common:btn.review-steps')}
        />
        <Button
          className={'btn -type_form '}
          type={'orange-light'}
          onPress={handleCreate}
          title={t('common:btn.create-audit')}
          enableEnter
        />
      </div>
    </div>
  );
};

export default Intro2;
