import React, { useEffect, useState } from 'react';
import { isMobile, isMobileOnly } from 'react-device-detect';
import InfiniteScroll from 'react-infinite-scroll-component';
import DataGrid from 'react-data-grid';
import { Button, Loader, RadioButton } from '../../../controls';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import {
  BelDebriefModel,
  DebriefBelovedUserModel,
  PaginationModel,
  TableDataEntities,
} from '../../../../models';
import { BelDebriefsState } from '../../../../store/beloved/debriefs/debriefsReducer';
import {
  getBelovedUsers,
  setDebriefLeader,
  setQuery,
} from '../../../../store/beloved/debriefs/debriefsActions';
import { ROOTS } from '../../../../routes/routes';
import { Alert, Modal, Pagination } from '../../../common';
import { getColumns } from './parts/columns';
import UserIc from '../../../../assets/images/icons/round-action-account-circle-black.svg';
import { basicDateFormat } from '../../../../helpers';
import useHasAccess from '../../../../hooks/beloved/adminTool/user-has-access';
import { BelovedPermissions } from '../../../../constants';
import { useTranslation } from 'react-i18next';
import { useIsDarkMode } from '../../../../hooks/common/use-is-dark-mode';
import useTableConfig from '../../../../hooks/common/use-table-config';
import useTableData from '../../../../hooks/common/use-table-data';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { showAlert } from '../../../common/Alert/RemoteAlert';
import SortModalButton from '../../../common/SortModalButton/SortModalButton';

interface IState {
  addLeaderFormId: number | null;
  selectedLeaderId: number | null;
}

interface ILocationState {
  org?: { id: number; name: string };
}

const Debriefs = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation<ILocationState | undefined>();
  let [notMobileAlert, setNotMobileAlert] = useState(false);
  const { isDarkMode } = useIsDarkMode();

  const hasAccessToEaReport = useHasAccess(BelovedPermissions.EA_REPORT);
  const hasAccessToAssignLead = useHasAccess(BelovedPermissions.DEBRIEF_ASSIGN_LEAD);
  const hasAccessToViewDebrief = useHasAccess(BelovedPermissions.DEBRIEF_VIEW_PRESENTATION);
  const hasAccessPmProfile = useHasAccess(BelovedPermissions.ORGANIZATION_PM_PROFILE);

  const {
    query,
    handleChangePageSize,
    handleChangePage,
    data,
    sortingData,
    setSortingData,
    fetchData,
    infiniteList,
    fetchMoreMobile,
    handleChangeSortField,
    handleChangeDirection,
    handleSearch,
    handleSort,
  } = useTableData(TableDataEntities.DEBRIEFS);

  const initialState: IState = {
    addLeaderFormId: null,
    selectedLeaderId: null,
  };

  let [state, setState] = useState<IState>(initialState);
  const [belovedUsers, setBelovedUsers] = useState<DebriefBelovedUserModel[] | null>(null);

  const onCellClick = (fieldName: string, debrief: BelDebriefModel) => {
    if (fieldName === 'debrief_leader') {
      handleOpenAddLeaderForm(debrief);
    }
  };

  const { columns, onColumnResize } = useTableConfig<BelDebriefModel>(
    TableDataEntities.DEBRIEFS,
    getColumns({ onCellClick }),
  );

  useEffect(() => {
    let newQuery: BelDebriefsState['query'] = { ...query };
    if (location.state) {
      if (location.state.org) {
        newQuery.search = location.state.org.name;
        newQuery.organization_id = location.state.org.id;
        newQuery.page = 1;
        dispatch(setQuery(newQuery));
      }
    }
    fetchData(newQuery);
  }, [location.key]);

  useEffect(() => {
    if (hasAccessToAssignLead) {
      dispatch(
        getBelovedUsers({
          callback: users => {
            setBelovedUsers(users);
            setState(prevState => ({ ...prevState, belovedUsers: users }));
          },
        }),
      );
    }
  }, [location.key, hasAccessToAssignLead]);

  const navToViewDebrief = (debrief: BelDebriefModel) => {
    if (!hasAccessToViewDebrief) {
      showAlert({
        title: '',
        text: t('awa:N78.msg'),
        buttons: {
          right: {
            title: 'OK',
          },
        },
      });
      return null;
    }
    if (isMobileOnly) {
      setNotMobileAlert(true);
    } else {
      history.push(
        ROOTS.VIEW_DEBRIEF.replace(':orgId', `${debrief.organization_id}`).replace(':eaId', `${debrief.id}`),
        { belovedApp: true },
      );
    }
  };

  const navToReport = (debrief: BelDebriefModel) => (e: any) => {
    e.stopPropagation();
    history.push(
      ROOTS.REPORTS.replace(':orgId', `${debrief.organization_id}`).replace(':eaId', `${debrief.id}`),
      { isBelovedApp: true },
    );
  };

  const navToPmProfile = (orgId: number) => {
    history.push(ROOTS.BELOVED_PM_PROFILE.replace(':orgId', `${orgId}`));
  };

  const handleOpenAddLeaderForm = (debrief: BelDebriefModel) => {
    setState({
      ...state,
      addLeaderFormId: debrief.id,
      selectedLeaderId: debrief.debrief_leader?.id || null,
    });
  };

  const handleCancelAddLeader = () => {
    setState({
      ...state,
      addLeaderFormId: null,
      selectedLeaderId: null,
    });
  };

  const handleSaveAddLeader = () => {
    if (state.selectedLeaderId && state.addLeaderFormId) {
      dispatch(
        setDebriefLeader({
          user_id: state.selectedLeaderId,
          debrief_id: state.addLeaderFormId,
          callback: () => {
            setState({
              ...state,
              addLeaderFormId: null,
              selectedLeaderId: null,
            });
            fetchData({ ...query });
          },
        }),
      );
    }
  };

  const handleLeadClick = (e: any, debrief: BelDebriefModel) => {
    e.stopPropagation();
    handleOpenAddLeaderForm(debrief);
  };

  const renderContent = () => {
    if (!data) return <Loader fitParent />;

    return (
      <>
        <div className="-desktop">
          <DndProvider backend={HTML5Backend}>
            <DataGrid
              onRowClick={navToViewDebrief}
              onColumnResize={onColumnResize}
              onSortColumnsChange={sortColumn => handleSort(sortColumn[0])}
              columns={columns}
              sortColumns={query.sorting ? [query.sorting] : []}
              rows={data.result}
              className={`${isDarkMode ? 'rdg-dark' : 'rdg-light'} b-rdgTable`}
              style={{
                height: ((data.result.length || 0) + 1) * 60,
              }}
              rowHeight={60}
              rowKeyGetter={(row: BelDebriefModel) => `deb-index-${row.id}`}
            />
          </DndProvider>
          <Pagination
            data={data || ({} as PaginationModel)}
            handleChangePageSize={handleChangePageSize}
            changePage={handleChangePage}
          />
        </div>
        {isMobile && (
          <div className="-mobile b-panelTitleOutside__list">
            <InfiniteScroll
              dataLength={infiniteList.length}
              next={fetchMoreMobile}
              hasMore={!(data.current_page === data.total_pages)}
              loader={<h4>{t('common:label.loading')}...</h4>}
            >
              {infiniteList.map(debrief => {
                return (
                  <div key={`mob-item-${debrief.id}`} className="b-panelTitleOutside__container">
                    <span className="b-panelTitleOutside__name">{debrief.organization_name}</span>
                    <div className="b-panelTitleOutside" onClick={() => navToViewDebrief(debrief)}>
                      <table>
                        <tr>
                          <th>{t('common:column.org-affiliation')}</th>
                          <td>{debrief.affiliation}</td>
                        </tr>
                        <tr>
                          <th>{t('common:column.org-type')}</th>
                          <td>{debrief.type}</td>
                        </tr>
                        <tr>
                          <th>{t('common:column.pm')}</th>
                          <td>
                            {debrief.project_manager ? (
                              hasAccessPmProfile ? (
                                <button
                                  onClick={() => navToPmProfile(debrief.organization_id)}
                                  className="b-equityAudits__pmLink"
                                >
                                  {debrief.project_manager.name}
                                  <img src={UserIc} alt={t('common:alt.profileIc')} />
                                </button>
                              ) : (
                                <>{debrief.project_manager.name}</>
                              )
                            ) : null}
                          </td>
                        </tr>
                        <tr>
                          <th>{t('common:column.debrief-scheduled-date')}</th>
                          <td>
                            {debrief.debrief_scheduled_date
                              ? basicDateFormat(new Date(debrief.debrief_scheduled_date))
                              : t('common:column.n-a')}
                          </td>
                        </tr>
                        <tr>
                          <th>{t('common:label.submission-date')}</th>
                          <td>
                            {debrief.submit_date
                              ? basicDateFormat(new Date(debrief.submit_date))
                              : t('common:column.n-a')}
                          </td>
                        </tr>
                        <tr>
                          <th>{t('common:column.leader')}</th>
                          <td>
                            <button
                              onClick={e => handleLeadClick(e, debrief)}
                              className="b-equityAudits__pmLink"
                            >
                              {debrief.debrief_leader?.name || t('common:btn.select-leader')}
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <th>{t('common:label.legacy-audits')}</th>
                          <td>
                            {debrief.legacy_audit ? (
                              <div className="d-flex h-100 align-items-center">
                                <div className="b-button-icon -checkmark" />
                              </div>
                            ) : null}
                          </td>
                        </tr>
                      </table>
                      <div className="b-panelTitleOutside__linksList">
                        {hasAccessToEaReport ? (
                          <button
                            type="button"
                            onClick={navToReport(debrief)}
                            className="b-panelTitleOutside__link"
                          >
                            <span className="b-button-icon -colorNavy -mh2  view" />
                            {t('common:column.ea-report')}
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <main className={'b-page'}>
        <div>
          <div className="b-page__header">
            <h1 className={'b-page__title -noDesktopMb'}>{t('main:debrief.debriefs')}</h1>
            <div className="b-page__search">
              <form action="#" className="searchForm">
                <input
                  type="search"
                  value={query.search}
                  onChange={e => handleSearch(e.target.value)}
                  placeholder={t('common:label.search')}
                  aria-label="Search field"
                />
                <span className="searchForm__icon" />
              </form>
            </div>
            <div className="-mobile">
              <div className="b-tableToolContainer -centered">
                <SortModalButton
                  sortingData={sortingData}
                  setSortingData={setSortingData}
                  columns={columns}
                  query={query}
                  handleChangeSortField={handleChangeSortField}
                  handleSort={handleSort}
                />
                <button
                  onClick={handleChangeDirection}
                  type="button"
                  className={`
                    b-tableTool
                    -mr
                    -order
                    -colorGrey
                    ${query.sorting?.direction === 'DESC' && '-active'}
                  `}
                >
                  {t('common:btn.a-z-order')}
                </button>
              </div>
            </div>
          </div>
          {renderContent()}
        </div>
      </main>
      {state.addLeaderFormId && (
        <Modal
          showCloseBtn={false}
          title={t('common:label.Add-Debrief-Leader')}
          onClose={handleCancelAddLeader}
        >
          <div className={'b-EAModal selectLeadForm'}>
            {belovedUsers && (
              <ul className="selectLeadForm__userList">
                {belovedUsers.map(user => {
                  return (
                    <li key={`bel-users-${user.user_id}`}>
                      <RadioButton
                        selectedValue={state.selectedLeaderId || ''}
                        value={user.user_id}
                        title={user.name_beloved_user}
                        onChangeValue={() => setState({ ...state, selectedLeaderId: user.user_id })}
                      />
                    </li>
                  );
                })}
              </ul>
            )}
            <div className="b-EAModal__buttons">
              <Button
                className={'btn -type_form'}
                type={'white'}
                onPress={handleCancelAddLeader}
                title={t('common:btn.cancel')}
              />
              <Button
                disabled={!state.selectedLeaderId}
                className={'btn -type_form'}
                type={'orange-light'}
                onPress={handleSaveAddLeader}
                title={t('common:btn.save')}
                enableEnter
              />
            </div>
          </div>
        </Modal>
      )}
      {notMobileAlert && (
        <Alert
          isOpen={notMobileAlert}
          onRequestClose={() => setNotMobileAlert(false)}
          title={t('awa:M54.title')}
          text={t('awa:M54.msg')}
        />
      )}
    </>
  );
};

export default Debriefs;
