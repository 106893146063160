import React, { useEffect, useState } from 'react';
import { useNavBack } from '../../../../hooks/common/use-nav-back';
import { AvatarZoomed } from '../../../common';
import { useHistory, useParams } from 'react-router';
import { BelovedUserModel, IRouteParamsBase } from '../../../../models';
import Api from '../../../../services/api';
import { Link, Loader } from '../../../controls';
import { sanitizedHTML } from '../../../../helpers';
import { useTranslation } from 'react-i18next';
import { ROOTS } from '../../../../routes/routes';
import { useSelector } from 'react-redux';
import { StateType } from '../../../../store/reducers';
import EmptyLink from '../../../controls/EmptyLink/EmptyLink';

const BelovedUserProfile = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { navBack } = useNavBack();
  const { id } = useParams<IRouteParamsBase>();
  const [data, setData] = useState<BelovedUserModel | null>(null);
  const user = useSelector((state: StateType) => state.profile.authUser);
  const isBeloved = user?.beloved_user;

  useEffect(() => {
    Api.get(`beloved_team/${id}/profile_beloved_user/`).then(response => {
      if (response) {
        setData(response);
      }
    });
  }, []);

  const navToAssignments = () => {
    if (!data) return;
    history.push(ROOTS.BEL_ORG_ASSIGNMENT_LIST, { name: `${data.first_name} ${data.last_name}` });
  };

  if (!data) {
    return <Loader />;
  }

  return (
    <main className="b-page">
      <div className="d-flex mb-4">
        <div>
          <h2 className="b-page__title2 -backIcon font-navy mb-3">
            <EmptyLink onClick={navBack} className="b-button-icon-back" />
            {data.first_name} {data.last_name}
          </h2>
          <div className="orange-light-text font-s font-weight-bold mb-3">{data.title}</div>
          <div className="font-grey font-s">{data.pronouns}</div>
          {data.email ? (
            <a
              rel="noreferrer"
              target="_blank"
              onClick={e => e.stopPropagation()}
              href={`mailto:${data.email}`}
              className="orange-light-text d-block font-s font-weight-bold mt-3 mb-3"
            >
              {data.email}
            </a>
          ) : null}
        </div>
        <div className="ml-5">
          <AvatarZoomed
            className="-w128"
            first_name={data.first_name}
            last_name={data.last_name}
            avatar={data.avatar}
          />
        </div>
      </div>
      <div
        style={{ lineHeight: '23px' }}
        className="b-editorText font-s"
        dangerouslySetInnerHTML={{ __html: sanitizedHTML(data.biography) }}
      />
      {isBeloved ? (
        <div className="mt-4">
          <Link onClick={navToAssignments} className="b-link-icon -group">
            {t('common:label.organization-assignments')}
          </Link>
        </div>
      ) : null}
    </main>
  );
};

export default BelovedUserProfile;
