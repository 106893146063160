import React, { useEffect, useState } from 'react';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { useRowSelection } from 'react-data-grid';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory, useLocation } from 'react-router';
import { Checkbox, Loader } from '../../../controls';
import { BelovedPermissions } from '../../../../constants';
import { BelEaModel, ColumnType, EAStatuses, QueryParamsModel, TableDataEntities } from '../../../../models';
import { ROOTS } from '../../../../routes/routes';
import { TableDateFilter, Alert, McTableTool, CheckedItems, FilterModalButton } from '../../../common';
import { basicDateFormat, getBelovedEaStatusClass } from '../../../../helpers';
import { getOrgIdsFromEntity } from '../../../../helpers';
import useHasAccess from '../../../../hooks/beloved/adminTool/user-has-access';
import { useTranslation } from 'react-i18next';
import { Popover } from 'react-tiny-popover';
import useFileDownload from '../../../../hooks/common/use-file-download';
import useBulkRemoveRequest, { BulkRemoveEntities } from '../../../../hooks/common/use-bulk-remove-request';
import SortModalButton from '../../../common/SortModalButton/SortModalButton';
import { Link } from 'react-router-dom';
import { useTableDataV2 } from '../../../../hooks/common/use-table-data-v2';
import { DataTable } from '../../../common/DataTable/DataTable';
import { useFilters } from '../../../../hooks/filters/use-filters';
import { eaStatusList } from '../../../../constants/ea';

interface ILocationState {
  org?: { id: number; name: string };
}

const EquityAudits = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation<ILocationState | undefined>();
  let [notMobileAlert, setNotMobileAlert] = useState(false);
  let [isDeletePopup, setIsDeletePopup] = useState(false);
  const [isOpenDownloadCSV, setIsOpenDownloadCSV] = useState<boolean>(false);
  const { isDownloadingFile, downloadFile } = useFileDownload();
  const { removeEntities } = useBulkRemoveRequest();

  const baseUrl = `beloved/equity_audit/${
    location.state?.org ? `?organization_id=${location.state.org.id}` : ''
  }`;

  const {
    query,
    data,
    fetchData,
    handleSearch,
    handleSort,
    setSortingData,
    sortingData,
    handleChangeDirection,
    handleChangeSortField,
    fetchMoreMobile,
    handleSelectOne,
    selectedEntitiesIds,
    selectedEntities,
    handleSelectOneMob,
    setSelectedEntitiesIds,
    filterData,
    handleSelectFilter,
    handleApplyFilter,
    isFiltersChanged,
    infiniteList,
  } = useTableDataV2<BelEaModel>({
    entityName: TableDataEntities.EA,
    baseUrl,
    enableSelectedEntities: true,
  });

  const initialColumns: ColumnType<BelEaModel>[] = [
    {
      key: 'organization_name',
      name: t('common:column.organization-name'),
      width: 170,
      sortable: true,
      editable: false,
      resizable: true,
    },
    {
      key: 'affiliation',
      name: t('common:column.affiliation'),
      sortable: true,
      editable: false,
      resizable: true,
    },
    {
      key: 'org_type',
      name: t('common:column.org-type'),
      sortable: true,
      editable: false,
      resizable: true,
      headerCellClass: 'b-rdgTable__headerCell',
      formatter(props) {
        return <>{props.row.type}</>;
      },
    },
    {
      key: 'status_name',
      name: t('common:column.status'),
      sortable: true,
      editable: false,
      resizable: true,
      formatter(props) {
        return (
          <span className={`b-tableStatusMark ${getBelovedEaStatusClass(props.row.status_id)}`}>
            {props.row.status_name}
          </span>
        );
      },
    },
    {
      key: 'last_update',
      name: t('common:column.last-update'),
      width: 90,
      sortable: true,
      editable: false,
      resizable: true,
      formatter(props) {
        return (
          <>
            {props.row.last_update
              ? basicDateFormat(new Date(props.row.last_update))
              : t('common:column.n-a')}
          </>
        );
      },
      headerRenderer(props) {
        const { handleSelectDateFilter, query } = useTableDataV2({
          entityName: TableDataEntities.EA,
          baseUrl,
          enableSelectedEntities: true,
        });
        if (query.dateFilters) {
          return (
            <TableDateFilter
              dateFilterId={'last_update'}
              selectedFilters={query.dateFilters['last_update']}
              periodFieldName={'last_update_period'}
              dateRangeFieldName={'last_update_date_range'}
              onDateFilterChanged={handleSelectDateFilter}
              rdgProps={props}
              fiscalOption={true}
            />
          );
        }
      },
    },
    {
      key: 'debrief_date',
      name: t('common:column.debrief'),
      width: 90,
      sortable: true,
      editable: false,
      resizable: true,
      formatter(props) {
        return (
          <>
            {props.row.debrief_date
              ? basicDateFormat(new Date(props.row.debrief_date))
              : t('common:column.n-a')}
          </>
        );
      },
      headerRenderer(props) {
        const { handleSelectDateFilter, query } = useTableDataV2({
          entityName: TableDataEntities.EA,
          baseUrl,
          enableSelectedEntities: true,
        });
        if (query.dateFilters) {
          return (
            <TableDateFilter
              dateFilterId={'debrief_date'}
              selectedFilters={query.dateFilters['debrief_date']}
              periodFieldName={'debrief_date_period'}
              dateRangeFieldName={'debrief_date_range'}
              onDateFilterChanged={handleSelectDateFilter}
              rdgProps={props}
              fiscalOption={true}
            />
          );
        }
      },
    },
    {
      key: 'created_at',
      name: t('common:column.create-date'),
      width: 90,
      isShow: false,
      sortable: true,
      editable: false,
      resizable: true,
      formatter(props) {
        return (
          <>
            {props.row.created_at ? basicDateFormat(new Date(props.row.created_at)) : t('common:column.n-a')}
          </>
        );
      },
      headerRenderer(props) {
        const { handleSelectDateFilter, query } = useTableDataV2({
          entityName: TableDataEntities.EA,
          baseUrl,
          enableSelectedEntities: true,
        });
        if (query.dateFilters) {
          return (
            <TableDateFilter
              dateFilterId={'created_at'}
              selectedFilters={query.dateFilters['created_at']}
              periodFieldName={'created_at_period'}
              dateRangeFieldName={'created_at_date_range'}
              onDateFilterChanged={handleSelectDateFilter}
              rdgProps={props}
              fiscalOption={true}
            />
          );
        }
      },
    },
    {
      key: 'submit_date',
      name: t('common:label.submission-date'),
      width: 90,
      isShow: false,
      sortable: true,
      editable: false,
      resizable: true,
      formatter(props) {
        return (
          <>
            {props.row.submit_date
              ? basicDateFormat(new Date(props.row.submit_date))
              : t('common:column.n-a')}
          </>
        );
      },
      headerRenderer(props) {
        const { handleSelectDateFilter, query } = useTableDataV2({
          entityName: TableDataEntities.EA,
          baseUrl,
          enableSelectedEntities: true,
        });
        if (query.dateFilters) {
          return (
            <TableDateFilter
              dateFilterId={'submit_date'}
              selectedFilters={query.dateFilters['submit_date']}
              periodFieldName={'submit_date_period'}
              dateRangeFieldName={'submit_date_range'}
              onDateFilterChanged={handleSelectDateFilter}
              rdgProps={props}
              fiscalOption={true}
            />
          );
        }
      },
    },
    {
      key: 'project_manager',
      name: t('common:label.organization-pm'),
      width: 90,
      sortable: true,
      editable: false,
      isShow: false,
      resizable: true,
      formatter(props) {
        if (!props.row.project_manager) {
          return <>{t('common:column.n-a')}</>;
        }
        const history = useHistory();
        const handleClick = (e: any) => {
          e.preventDefault();
          e.stopPropagation();
          history.push(ROOTS.BELOVED_PM_PROFILE.replace(':orgId', `${props.row.organization_id}`));
        };
        return (
          <a className="b-link" href="#" onClick={handleClick}>
            {props.row.project_manager.name}
          </a>
        );
      },
    },
    {
      key: 'legacy_audit',
      name: t('common:label.legacy-audit'),
      width: 90,
      isShow: false,
      sortable: true,
      editable: false,
      resizable: true,
      formatter(props) {
        if (!props.row.legacy_audit) {
          return null;
        }
        return (
          <div className="d-flex justify-content-center h-100 align-items-center">
            <div className="b-button-icon -checkmark" />
          </div>
        );
      },
    },
    {
      key: 'tools',
      name: '',
      disableDrag: true,
      width: 70,
      maxWidth: 70,
      formatter: props => {
        const hasAccessToEaReport = useHasAccess(BelovedPermissions.EA_REPORT);
        const hasAccessToViewDebrief = useHasAccess(BelovedPermissions.DEBRIEF_VIEW_PRESENTATION);

        return (
          <div className="b-rdgTable__cellCenter">
            {props.row.has_report && hasAccessToEaReport && (
              <button
                onClick={e => {
                  e.stopPropagation();
                  navToReport(props.row);
                }}
                className="b-button-icon ea-report -colorNavy -mh2"
                aria-label="Detailed Report"
              />
            )}
            {props.row.has_debrief && hasAccessToViewDebrief && (
              <button
                onClick={e => {
                  e.stopPropagation();
                  navToDebrief(props.row);
                }}
                className="b-button-icon -colorNavy -mh2  desktop"
                aria-label="Equity audit debrief"
              />
            )}
          </div>
        );
      },
    },
    {
      key: 'select',
      name: '',
      width: 40,
      maxWidth: 40,
      disableDrag: true,
      headerRenderer(props) {
        return (
          <Checkbox
            checked={props.allRowsSelected}
            className="b-equityAudits__checkbox-header"
            onChange={e => props.onAllRowsSelectionChange(e.target.checked)}
          />
        );
      },
      formatter: props => {
        const [isRowSelected, onRowSelectionChange] = useRowSelection();

        return (
          <div className="b-rdgTable__cellEnd">
            <div className={'checkBox b-equityAudits__checkbox'} onClick={e => e.stopPropagation()}>
              <Checkbox
                checked={isRowSelected}
                onChange={e => {
                  onRowSelectionChange({
                    row: props.row,
                    checked: e.target.checked,
                    isShiftClick: false,
                  });
                }}
              />
            </div>
          </div>
        );
      },
    },
  ];

  const hasAccessToEaReport = useHasAccess(BelovedPermissions.EA_REPORT);
  const hasAccessToViewDebrief = useHasAccess(BelovedPermissions.DEBRIEF_VIEW_PRESENTATION);
  const hasAccessToRmEa = useHasAccess(BelovedPermissions.EA_REMOVE);
  const hasAccessToMarketingComCreate = useHasAccess(BelovedPermissions.MARKETING_COMMUNICATION_CREATE);

  const { filterOptions } = useFilters();

  useEffect(() => {
    let newQuery: QueryParamsModel = { ...query };
    if (location.state) {
      if (location.state.org) {
        newQuery.search = location.state.org.name;
        newQuery.page = 1;
      }
    }
    fetchData(newQuery);
  }, [location.key]);

  const navToDetails = (ea: BelEaModel) => {
    history.push(ROOTS.BELOVED_EQUITY_AUDIT_DETAILS.replace(':eaId', `${ea.id}`));
  };

  const navToReport = (ea: BelEaModel) => {
    history.push(ROOTS.REPORTS.replace(':orgId', `${ea.organization_id}`).replace(':eaId', `${ea.id}`), {
      isBelovedApp: true,
    });
  };

  const navToDebrief = (ea: BelEaModel) => {
    if (isMobileOnly) {
      setNotMobileAlert(true);
    } else {
      history.push(
        ROOTS.VIEW_DEBRIEF.replace(':orgId', `${ea.organization_id}`).replace(':eaId', `${ea.id}`),
        { belovedApp: true },
      );
    }
  };

  const handleDownload = (alias: 'info' | 'scores', isAll?: boolean) => {
    const body = {
      ea_ids: selectedEntitiesIds,
      option: alias,
      all: false,
    };
    if (isAll) {
      body.all = true;
    } else if (!selectedEntitiesIds.length) {
      return;
    }
    setIsOpenDownloadCSV(false);
    downloadFile<typeof body>(`beloved/equity_audit/export_equity_audit/`, 'POST', body);
  };

  const renderDownloadContent = () => {
    return (
      <div className="b-dropDownMenu">
        <ul>
          <li
            className={`${!selectedEntitiesIds.length ? '-disabled' : ''}`}
            onClick={() => handleDownload('info')}
          >
            {t('common:btn.ea-info')}
          </li>
          <li onClick={() => handleDownload('info', true)}>{t('common:btn.ea-info-all')}</li>
          <li
            className={`${!selectedEntitiesIds.length ? '-disabled' : ''}`}
            onClick={() => handleDownload('scores')}
          >
            {t('common:btn.ea-scores')}
          </li>
          <li onClick={() => handleDownload('scores', true)}>{t('common:btn.ea-scores-all')}</li>
        </ul>
      </div>
    );
  };

  const handleDelete = () => {
    removeEntities(BulkRemoveEntities.EA, selectedEntitiesIds, () => {
      fetchData(query);
      setSelectedEntitiesIds([]);
      setIsDeletePopup(false);
    });
  };

  const handleClickGa = () => {
    const ids = _getSelectedCompletedItems().map(item => item.id);
    history.push(ROOTS.BEL_GROUP_ANALYSIS, ids);
  };

  const handleClickAvg = () => {
    history.push(ROOTS.BEL_GROUP_AVERAGES_SAVED);
  };

  const _getSelectedCompletedItems = (): BelEaModel[] => {
    return selectedEntities.filter(item => item.status_id === EAStatuses.COMPLETED);
  };

  const renderContent = () => {
    if (!data || isDownloadingFile) return <Loader fitParent />;

    return (
      <>
        <div className="-desktop position-relative">
          <CheckedItems value={selectedEntitiesIds.length} total={data.count} />
          <DataTable<BelEaModel>
            baseUrl={baseUrl}
            onRowClick={navToDetails}
            entityName={TableDataEntities.EA}
            selectedEntitiesIds={selectedEntitiesIds}
            handleSelectOne={handleSelectOne}
            cols={initialColumns}
          />
        </div>
        {isMobile && (
          <div className="-mobile b-panelTitleOutside__list">
            <InfiniteScroll
              dataLength={infiniteList.length}
              next={fetchMoreMobile}
              hasMore={!(data.current_page === data.total_pages)}
              loader={<h4>{t('common:label.loading')}...</h4>}
            >
              {infiniteList.map((ea: BelEaModel) => {
                const isChecked = !!selectedEntitiesIds.find(f => f === ea.id);

                return (
                  <div key={`mob-item-${ea.id}`} className="b-panelTitleOutside__container">
                    <span className="b-panelTitleOutside__name">{ea.organization_name}</span>
                    <div className="b-panelTitleOutside" onClick={() => navToDetails(ea)}>
                      <label className="checkbox b-at-user__check" onClick={e => e.stopPropagation()}>
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={e => handleSelectOneMob(e, ea.id)}
                        />
                        <span className="checkbox_fakeInput" />
                      </label>
                      <table>
                        <tr>
                          <th>{t('common:column.org-affiliation')}</th>
                          <td>{ea.affiliation}</td>
                        </tr>
                        <tr>
                          <th>{t('common:column.org-type')}</th>
                          <td>{ea.type}</td>
                        </tr>
                        <tr>
                          <th>{t('common:column.status')}</th>
                          <td>
                            <span className={`b-tableStatusMark ${getBelovedEaStatusClass(ea.status_id)}`}>
                              {ea.status_name}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>{t('common:column.last-update')}</th>
                          <td>
                            {ea.last_update
                              ? basicDateFormat(new Date(ea.last_update))
                              : t('common:column.n-a')}
                          </td>
                        </tr>
                        <tr>
                          <th>{t('common:column.create-date')}</th>
                          <td>
                            {ea.created_at
                              ? basicDateFormat(new Date(ea.created_at))
                              : t('common:column.n-a')}
                          </td>
                        </tr>
                        <tr>
                          <th>{t('common:label.submission-date')}</th>
                          <td>
                            {ea.submit_date
                              ? basicDateFormat(new Date(ea.submit_date))
                              : t('common:column.n-a')}
                          </td>
                        </tr>
                        <tr>
                          <th>{t('common:label.organization-pm')}</th>
                          <td>
                            {ea.project_manager ? (
                              <Link
                                className="b-link"
                                to={ROOTS.BELOVED_PM_PROFILE.replace(':orgId', `${ea.organization_id}`)}
                              >
                                {ea.project_manager.name}
                              </Link>
                            ) : (
                              <span>{t('common:column.n-a')}</span>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th>{t('common:label.legacy-audit')}</th>
                          <td>
                            {ea.legacy_audit ? (
                              <div className="d-flex h-100 align-items-center">
                                <div className="b-button-icon -checkmark" />
                              </div>
                            ) : null}
                          </td>
                        </tr>
                      </table>
                      <div className="b-panelTitleOutside__linksList">
                        {ea.has_report && hasAccessToEaReport ? (
                          <button
                            className="b-panelTitleOutside__link"
                            type="button"
                            onClick={e => {
                              e.stopPropagation();
                              navToReport(ea);
                            }}
                          >
                            <span className="b-button-icon -colorNavy -mh2  view" />
                            {t('common:column.ea-report')}
                          </button>
                        ) : null}
                        {ea.has_debrief && hasAccessToViewDebrief ? (
                          <button
                            type="button"
                            onClick={e => {
                              e.stopPropagation();
                              navToDebrief(ea);
                            }}
                            className="b-panelTitleOutside__link"
                          >
                            <span className="b-button-icon -colorNavy -mh2  edit" />
                            {t('common:btn.debriefs')}
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <main className={'b-page'}>
        <div>
          <h1 className={'b-page__title'}>{t('common:tabs.ea')}</h1>
          <div className="b-page__controls">
            <div className="b-tableToolContainer">
              <button
                onClick={handleClickGa}
                disabled={!_getSelectedCompletedItems()?.length}
                className="b-tableTool -ga -mobView -mr"
              >
                {t('common:tabs.group-analysis')}
              </button>
              <button onClick={handleClickAvg} className="b-tableTool -ga -mobView -mr">
                {t('common:tabs.group-averages')}
              </button>
              <Popover
                isOpen={isOpenDownloadCSV}
                positions={['bottom']}
                content={renderDownloadContent()}
                align={'start'}
                reposition={false}
                containerClassName={'popover'}
                onClickOutside={() => setIsOpenDownloadCSV(false)}
              >
                <div>
                  <button
                    aria-label="Download"
                    onClick={() => setIsOpenDownloadCSV(!isOpenDownloadCSV)}
                    className={`b-tableTool -download -mobView -mr`}
                  >
                    <span className={'-desktop'}>{t('common:btn.download')}</span>
                  </button>
                </div>
              </Popover>
              {filterOptions ? (
                <FilterModalButton
                  query={query}
                  handleSelectFilter={handleSelectFilter}
                  handleApplyFilter={handleApplyFilter}
                  filterData={filterData}
                  isFiltersChanged={isFiltersChanged}
                  filters={filterOptions.equity_audit}
                  additionalFilter={
                    <>
                      <li>
                        <span>{t('common:column.status')}:</span>
                      </li>
                      <li>
                        <ul>
                          {Object.values(eaStatusList).map(item => {
                            const isActive = filterData.status?.includes(item.id);
                            return (
                              <li key={`filter-status-${item.id}`}>
                                <a
                                  href="#"
                                  className={isActive ? '-active' : ''}
                                  onClick={e => {
                                    e.preventDefault();
                                    handleSelectFilter(item.id, 'status');
                                  }}
                                >
                                  {item.title}
                                </a>
                              </li>
                            );
                          })}
                          <li>
                            <a
                              href="#"
                              className={filterData.debrief ? '-active' : ''}
                              onClick={e => {
                                e.preventDefault();
                                handleSelectFilter(1, 'debrief');
                              }}
                            >
                              {t('common:column.debrief')}
                            </a>
                          </li>
                        </ul>
                      </li>
                    </>
                  }
                />
              ) : null}
              {hasAccessToRmEa ? (
                <button
                  onClick={() => setIsDeletePopup(true)}
                  disabled={!selectedEntitiesIds.length}
                  className="b-tableTool -delete -mobView -mr"
                >
                  {t('common:btn.delete')}
                </button>
              ) : null}
              {hasAccessToMarketingComCreate ? (
                <McTableTool orgIds={getOrgIdsFromEntity(selectedEntitiesIds, data)} />
              ) : null}
            </div>
            <div className="b-page__search">
              <form action="#" className="searchForm">
                <input
                  type="search"
                  value={query.search}
                  onChange={e => handleSearch(e.target.value)}
                  placeholder={t('common:label.search')}
                  aria-label="Search field"
                />
                <span className="searchForm__icon" />
              </form>
            </div>
          </div>
          <div className="-mobile">
            <div className="b-tableToolContainer -centered">
              <SortModalButton
                sortingData={sortingData}
                setSortingData={setSortingData}
                columns={initialColumns}
                query={query}
                handleChangeSortField={handleChangeSortField}
                handleSort={handleSort}
              />
              <button
                onClick={handleChangeDirection}
                type="button"
                className={`
                    b-tableTool
                    -mr
                    -order
                    -colorGrey
                    ${query.sorting?.direction === 'DESC' && '-active'}
                  `}
              >
                {t('common:btn.a-z-order')}
              </button>
            </div>
          </div>
        </div>
        {renderContent()}
      </main>
      {notMobileAlert && (
        <Alert
          isOpen={notMobileAlert}
          onRequestClose={() => setNotMobileAlert(false)}
          title={t('awa:M54.title')}
          text={t('awa:M54.msg')}
        />
      )}
      {isDeletePopup ? (
        <Alert
          isOpen={isDeletePopup}
          onRequestClose={() => setIsDeletePopup(false)}
          title={t('awa:N38.title')}
          text={<p />}
          buttons={{
            left: {
              type: 'bordered',
              title: t('common:btn.cancel'),
              onClick: () => setIsDeletePopup(false),
            },
            right: {
              title: t('common:btn.delete'),
              onClick: handleDelete,
            },
          }}
        />
      ) : null}
    </>
  );
};

export default EquityAudits;
