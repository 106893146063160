import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import DataGrid from 'react-data-grid';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useHistory, useLocation } from 'react-router';
import { Loader } from '../../../controls';
import { BelElmModel, PaginationModel, QueryParamsModel, TableDataEntities } from '../../../../models';
import { ROOTS } from '../../../../routes/routes';
import { getColumns } from './parts/columns';
import { Alert, McTableTool, Pagination } from '../../../common';
import {
  getBelovedElmStatusClass,
  basicDateFormat,
  isEmptyObject,
  getOrgIdsFromEntity,
} from '../../../../helpers';
import { useTranslation } from 'react-i18next';
import useHasAccess from '../../../../hooks/beloved/adminTool/user-has-access';
import { BelovedPermissions, Modals, ModalsModel } from '../../../../constants';
import { useIsDarkMode } from '../../../../hooks/common/use-is-dark-mode';
import useBulkRemoveRequest, { BulkRemoveEntities } from '../../../../hooks/common/use-bulk-remove-request';
import SortModalButton from '../../../common/SortModalButton/SortModalButton';
import useTableConfig from '../../../../hooks/common/use-table-config';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { Link } from 'react-router-dom';
import { Popover } from 'react-tiny-popover';
import useFileDownload from '../../../../hooks/common/use-file-download';
import FilterModal from './parts/FilterModal';
import { useTableDataV2 } from '../../../../hooks/common/use-table-data-v2';
import CheckedItems from '../../../common/CheckedItems/CheckedItems';

interface ILocationState {
  org?: { id: number; name: string };
}

const Elms = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation<ILocationState | undefined>();
  const { isDarkMode } = useIsDarkMode();
  const { removeEntities } = useBulkRemoveRequest();

  const {
    query,
    data,
    fetchData,
    handleChangePage,
    handleChangePageSize,
    handleSearch,
    handleSort,
    handleChangeDirection,
    handleChangeSortField,
    fetchMoreMobile,
    sortingData,
    setSortingData,
    selectedEntitiesIds,
    handleSelectOne,
    handleSelectOneMob,
    setSelectedEntitiesIds,
    infiniteList,
  } = useTableDataV2<BelElmModel>({
    baseUrl: 'beloved/elm/',
    entityName: TableDataEntities.ELM,
  });

  const onCellClick = (fieldName: string, elm: BelElmModel) => {
    if (fieldName === 'navReport') {
      navToReport(elm);
    }
  };

  const { columns, onColumnResize } = useTableConfig<BelElmModel>(
    TableDataEntities.ELM,
    getColumns({ onCellClick }),
  );

  const hasAccessToElmReport = useHasAccess(BelovedPermissions.ELM_VIEW_REPORT);
  const hasAccessToRmElm = useHasAccess(BelovedPermissions.ELM_REMOVE);
  const hasAccessToMarketingComCreate = useHasAccess(BelovedPermissions.MARKETING_COMMUNICATION_CREATE);

  const { isDownloadingFile, downloadFile } = useFileDownload();
  const [modals, setModals] = useState<ModalsModel>({});
  const [isOpenDownloadCSV, setIsOpenDownloadCSV] = useState<boolean>(false);

  let [isDeletePopup, setIsDeletePopup] = useState(false);

  useEffect(() => {
    let newQuery: QueryParamsModel = { ...query };
    if (location.state) {
      if (location.state.org) {
        newQuery.search = location.state.org.name;
        newQuery.page = 1;
      }
    }
    fetchData(newQuery);
  }, [location.key]);

  const navToDetails = (elm: BelElmModel) => {
    history.push(ROOTS.BELOVED_ELM_DETAILS.replace(':elmId', `${elm.id}`));
  };

  const navToReport = (elm: BelElmModel) => {
    history.push(
      ROOTS.ELM_ORG_REPORT_RESP.replace(':orgId', `${elm.organization_id}`).replace(':elmId', `${elm.id}`),
      { isBelovedApp: true },
    );
  };

  const handleDelete = () => {
    removeEntities(BulkRemoveEntities.ELM, selectedEntitiesIds, () => {
      fetchData(query);
      setSelectedEntitiesIds([]);
      setIsDeletePopup(false);
    });
  };

  const renderContent = () => {
    if (!data || isDownloadingFile) return <Loader fitParent />;

    return (
      <>
        <div className="-desktop position-relative">
          <CheckedItems value={selectedEntitiesIds.length} total={data.count} />
          <DndProvider backend={HTML5Backend}>
            <DataGrid
              onRowClick={navToDetails}
              onSortColumnsChange={sortColumn => handleSort(sortColumn[0])}
              columns={columns}
              onColumnResize={onColumnResize}
              sortColumns={query.sorting ? [query.sorting] : []}
              rows={data.result}
              className={`${isDarkMode ? 'rdg-dark' : 'rdg-light'} b-rdgTable`}
              style={{
                height: ((data.result.length || 0) + 1) * 60,
              }}
              rowHeight={60}
              rowKeyGetter={(row: BelElmModel) => row.id}
              onSelectedRowsChange={handleSelectOne}
              selectedRows={new Set(selectedEntitiesIds)}
            />
          </DndProvider>
          <Pagination
            data={data || ({} as PaginationModel)}
            handleChangePageSize={handleChangePageSize}
            changePage={handleChangePage}
          />
        </div>
        {isMobile && (
          <div className="-mobile b-panelTitleOutside__list">
            <InfiniteScroll
              dataLength={infiniteList.length}
              next={fetchMoreMobile}
              hasMore={!(data.current_page === data.total_pages)}
              loader={<h4>{t('common:label.loading')}...</h4>}
            >
              {infiniteList.map((elm: BelElmModel) => {
                const isChecked = !!selectedEntitiesIds.find(f => f === elm.id);
                return (
                  <div key={`mob-item-${elm.id}`} className="b-panelTitleOutside__container">
                    <span className="b-panelTitleOutside__name">{elm.organization_name}</span>
                    <div className="b-panelTitleOutside" onClick={() => navToDetails(elm)}>
                      <label className="checkbox b-at-user__check" onClick={e => e.stopPropagation()}>
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={e => handleSelectOneMob(e, elm.id)}
                        />
                        <span className="checkbox_fakeInput" />
                      </label>
                      <table>
                        <tr>
                          <th>{t('common:column.org-affiliation')}</th>
                          <td>{elm.affiliation}</td>
                        </tr>
                        <tr>
                          <th>{t('common:column.type')}</th>
                          <td>{elm.type}</td>
                        </tr>
                        <tr>
                          <th>{t('common:column.status')}</th>
                          <td>
                            <span className={`b-tableStatusMark ${getBelovedElmStatusClass(elm.status_id)}`}>
                              {elm.status_name}
                            </span>
                            <div>
                              {elm.status_percent !== null
                                ? `${elm.status_percent}%`
                                : t('common:column.n-a')}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>{t('common:label.administration-type')}</th>
                          <td>{elm.administration_type || t('common:column.n-a')}</td>
                        </tr>
                        <tr>
                          <th>{t('common:label.created-by')}</th>
                          <td>
                            <Link
                              className="b-link"
                              to={ROOTS.BELOVED_PM_PROFILE.replace(':orgId', `${elm.organization_id}`)}
                            >
                              {elm.created_by.name}
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <th>{t('common:column.create-date')}</th>
                          <td>
                            {elm.start_date
                              ? basicDateFormat(new Date(elm.created_at))
                              : t('common:column.n-a')}
                          </td>
                        </tr>
                        <tr>
                          <th>{t('common:column.start-date')}</th>
                          <td>
                            {elm.start_date
                              ? basicDateFormat(new Date(elm.start_date))
                              : t('common:column.n-a')}
                          </td>
                        </tr>
                        <tr>
                          <th>{t('common:column.end-date')}</th>
                          <td>
                            {elm.end_date ? basicDateFormat(new Date(elm.end_date)) : t('common:column.n-a')}
                          </td>
                        </tr>
                        <tr>
                          <th>{t('common:column.Beloved-Leader')}</th>
                          <td>{elm.beloved_leader ? elm.beloved_leader.name : t('common:column.n-a')}</td>
                        </tr>
                      </table>
                      <div className="b-panelTitleOutside__linksList">
                        {elm.has_org_report && hasAccessToElmReport ? (
                          <button
                            className="b-panelTitleOutside__link"
                            type="button"
                            onClick={e => {
                              e.stopPropagation();
                              navToReport(elm);
                            }}
                          >
                            <span className="b-button-icon -colorNavy -mh2  mobile" />
                            {t('common:label.ELM-Organization-Report')}
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>
        )}
      </>
    );
  };

  const handleDownload = (alias: 'org' | 'individual', isAll?: boolean) => {
    if (!data) {
      return;
    }
    const body = {
      elm_ids: selectedEntitiesIds,
      option: alias,
      all: false,
    };
    if (isAll) {
      body.all = true;
    } else if (!selectedEntitiesIds.length) {
      return;
    }
    setIsOpenDownloadCSV(false);
    downloadFile(`beloved/elm/export_report_elm/`, 'POST', body);
  };

  const manipulateModal = (modalName: string, open: boolean) => {
    setModals(prevState => ({
      ...prevState,
      [modalName]: open,
    }));
  };

  const renderDownloadContent = () => {
    return (
      <div className="b-dropDownMenu">
        <ul>
          <li
            className={`${!selectedEntitiesIds.length ? '-disabled' : ''}`}
            onClick={() => handleDownload('org')}
          >
            {t('common:btn.organization-report')}
          </li>
          <li onClick={() => handleDownload('org', true)}>{t('common:btn.organization-report-all')}</li>
          <li
            className={`${!selectedEntitiesIds.length ? '-disabled' : ''}`}
            onClick={() => handleDownload('individual')}
          >
            {t('common:btn.individual-responses')}
          </li>
          <li onClick={() => handleDownload('individual', true)}>
            {t('common:btn.individual-responses-all')}
          </li>
        </ul>
      </div>
    );
  };

  return (
    <>
      <main className={'b-page'}>
        <div>
          <h1 className={'b-page__title'}>{t('common:tabs.elms')}</h1>
          <div className="b-page__controls">
            <div className="b-tableToolContainer">
              <Popover
                isOpen={isOpenDownloadCSV}
                positions={['bottom']}
                content={renderDownloadContent()}
                align={'start'}
                reposition={false}
                containerClassName={'popover'}
                onClickOutside={() => setIsOpenDownloadCSV(false)}
              >
                <div>
                  <button
                    aria-label="Download"
                    onClick={() => setIsOpenDownloadCSV(!isOpenDownloadCSV)}
                    className={`b-tableTool -download -mobView -mr`}
                  >
                    <span className={'-desktop'}>{t('common:btn.download')}</span>
                  </button>
                </div>
              </Popover>
              <button
                aria-label="Filter"
                onClick={() => manipulateModal(Modals.isFilter, true)}
                className={`
                  b-tableTool 
                  -colorGrey 
                  -filter 
                  -mobView 
                  -mr
                  ${!isEmptyObject(query.filters) ? '-active' : ''}
                  `}
              >
                <span className={'-desktop'}>{t('common:btn.filter')}</span>
              </button>
              {hasAccessToRmElm ? (
                <button
                  onClick={() => setIsDeletePopup(true)}
                  disabled={!selectedEntitiesIds.length}
                  className="b-tableTool -delete -mobView -mr"
                >
                  {t('common:btn.delete')}
                </button>
              ) : null}
              {hasAccessToMarketingComCreate ? (
                <McTableTool orgIds={getOrgIdsFromEntity(selectedEntitiesIds, data)} />
              ) : null}
            </div>
            <div className="b-page__search">
              <form action="#" className="searchForm">
                <input
                  type="search"
                  value={query.search}
                  onChange={e => handleSearch(e.target.value)}
                  placeholder={t('common:label.search')}
                  aria-label={'search'}
                />
                <span className="searchForm__icon" />
              </form>
            </div>
          </div>

          <div className="-mobile">
            <div className="b-tableToolContainer -centered">
              <SortModalButton
                sortingData={sortingData}
                setSortingData={setSortingData}
                columns={columns}
                query={query}
                handleChangeSortField={handleChangeSortField}
                handleSort={handleSort}
              />
              <button
                onClick={handleChangeDirection}
                type="button"
                className={`
                    b-tableTool
                    -mr
                    -order
                    -colorGrey
                    ${query.sorting?.direction === 'DESC' && '-active'}
                  `}
              >
                {t('common:btn.a-z-order')}
              </button>
            </div>
          </div>
        </div>
        {renderContent()}
      </main>
      {isDeletePopup ? (
        <Alert
          isOpen={isDeletePopup}
          onRequestClose={() => setIsDeletePopup(false)}
          title={t('awa:N38.title')}
          text={<p />}
          buttons={{
            left: {
              type: 'bordered',
              title: t('common:btn.cancel'),
              onClick: () => setIsDeletePopup(false),
            },
            right: {
              title: t('common:btn.delete'),
              onClick: handleDelete,
            },
          }}
        />
      ) : null}
      {modals.isFilter ? <FilterModal onClose={() => manipulateModal(Modals.isFilter, false)} /> : null}
    </>
  );
};

export default Elms;
