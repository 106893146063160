import { t } from 'i18next';
import { KeyValueModel } from '../models';

export interface ModalsModel {
  isDeactivateUser?: boolean;
  isDeleteAffiliation?: boolean | number;
  isAddAffiliation?: boolean;
  isDeleteUser?: boolean;
  isResendEmail?: boolean | number | string;
  isActivateUser?: boolean;
  isFilter?: boolean;
  isSorting?: boolean;
  isInfo?: boolean;
  isResetPassword?: boolean;
  isScoreGuide?: boolean;
  isGlobalResultInfo?: boolean;
  isMobileChartImpossible?: boolean;
  isUserRoleModal?: boolean;
  isPayment?: boolean;
  isOrgSetupInfo?: boolean;
  isSameOrgAssign?: boolean;
  isDeleteTask?: boolean | number;
  isReschedule?: boolean;
  isAddUser?: boolean;
  isDelete?: boolean;
  isMaxUsers?: boolean;
  isExpiredHash?: boolean;
  isSaveAverage?: boolean;
  isConfirmEmail?: any;
  isSetRole?: boolean;
  isUpdateAverage?: boolean;
}

export enum Modals {
  isDeactivateUser = 'isDeactivateUser',
  isDeleteUser = 'isDeleteUser',
  isResendEmail = 'isResendEmail',
  isDeleteAffiliation = 'isDeleteAffiliation',
  isAddAffiliation = 'isAddAffiliation',
  isActivateUser = 'isActivateUser',
  isFilter = 'isFilter',
  isSorting = 'isSorting',
  isInfo = 'isInfo',
  isResetPassword = 'isResetPassword',
  isScoreGuide = 'isScoreGuide',
  isGlobalResultInfo = 'isGlobalResultInfo',
  isMobileChartImpossible = 'isMobileChartImpossible',
  isUserRoleModal = 'isUserRoleModal',
  isPayment = 'isPayment',
  isOrgSetupInfo = 'isOrgSetupInfo',
  isSameOrgAssign = 'isSameOrgAssign',
  isDeleteTask = 'isDeleteTask',
  isReschedule = 'isReschedule',
  isDelete = 'isDelete',
  isAddUser = 'isAddUser',
  isMaxUsers = 'isMaxUsers',
  isExpiredHash = 'isExpiredHash',
  isConfirmEmail = 'isConfirmEmail',
  isSaveAverage = 'isSaveAverage',
  isUpdateAverage = 'isUpdateAverage',
  isSetRole = 'isSetRole',
}

export const modalNames = (): KeyValueModel<string> => {
  return {
    ethnicity_data: t('common:label.ethnicity'),
    gender_data: t('common:label.gender'),
    race_data: t('common:label.race'),
    education_data: t('common:label.education'),
    pronouns_data: t('common:label.pronouns'),
    ethnicity: t('common:label.ethnicity'),
    gender: t('common:label.gender'),
    race: t('common:label.race'),
    education: t('common:label.education'),
    pronouns: t('common:label.pronouns'),
    userRoleModal: t('common:label.Assign-User-Role'),
  };
};
