import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { StateType } from '../../../../store/reducers';
import { BelovedUserCard, HeadlineOrganization, OrgBreadcrumbs, OrgProfileTabs } from '../../../common';
import { useInfiniteList } from '../../../../hooks/common/use-infinite-list';
import { BelovedUserShortModel, IRouteParamsBase } from '../../../../models';
import { useParams } from 'react-router';
import { Loader } from '../../../controls';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import Illus from '../../../../assets/images/icons/illus_next_steps.png';
import { convertToURL } from '../../../../helpers';
import EmptyLink from '../../../controls/EmptyLink/EmptyLink';

const OrgBelovedUserList = () => {
  const { orgId } = useParams<IRouteParamsBase>();
  const { t } = useTranslation();
  const currentOrg = useSelector((state: StateType) => state.organization.currentOrg);
  const { fetchMoreData, fetchData, data } = useInfiniteList<BelovedUserShortModel>(
    `organizations/${orgId}/beloved_team/`,
  );

  useEffect(() => {
    fetchData();
  }, []);

  const renderContent = () => {
    if (!data) {
      return <Loader />;
    }

    if (!data.result.length) {
      return (
        <div className="b-nextSteps mt-4">
          <img
            className="b-nextSteps__illustration"
            width={122}
            height={122}
            src={Illus}
            alt={t('awa:AT25')}
          />
          <div className="b-nextSteps__holder">
            <h2 className="b-nextSteps__title">
              {t('main:belovedTeam.no-user-org-app.p1')}&nbsp;
              <span className="font-primary80">{t('main:belovedTeam.no-user-org-app.p2')}</span>
            </h2>
            <p>{t('main:belovedTeam.no-user-org-app.p3')}</p>
            <p>{t('main:belovedTeam.no-user-org-app.p4')}</p>
            <b>{t('main:belovedTeam.no-user-org-app.p5')}</b>
            <ul className="b-nextSteps__list">
              <li>
                <div className="b-nextSteps__listIcon -item2" />
                {t('awa:M73.msg.p4')}
              </li>
              <li>
                <div className="b-nextSteps__listIcon -item3" />
                {t('awa:M73.msg.p5')}
              </li>
              <li>
                <div className="b-nextSteps__listIcon -item1" />
                {t('awa:M73.msg.p6')}
              </li>
            </ul>
            <p className="mb-5">
              <b className="font-primary80">{t('main:belovedTeam.no-user-org-app.p6')}</b>&nbsp;
              {t('main:belovedTeam.no-user-org-app.p7')}&nbsp;
              <a
                target="_blank"
                rel="noreferrer"
                className="orange-light-text"
                href={convertToURL('https://www.wearebeloved.org/consulting')}
              >
                {t('main:belovedTeam.no-user-org-app.p8')}
              </a>
            </p>
            <a
              rel="noreferrer"
              target="_blank"
              className="b-nextSteps__linkBtn"
              href="mailto:support@awabybeloved.freshdesk.com?subject=Request for Beloved Consulting Information&body=I'm interested in learning about Beloved Consulting.  Please, contact me via phone <please add your phone number> or email.%0d%0a%0d%0aThank you!"
            >
              {t('awa:M73.msg.p7')}
            </a>
          </div>
        </div>
      );
    }

    return (
      <>
        <div
          className="font-m font-grey font-weight-bold text-align-center mt-4 mb-5"
          style={{ maxWidth: 900 }}
        >
          {t('main:belovedTeam.assigned-team')}
        </div>
        <div className="b-infScrollWrapper">
          <InfiniteScroll
            dataLength={data.result.length}
            next={fetchMoreData}
            hasMore={!(data.current_page === data.total_pages)}
            loader={<h4 className="b-no-content">{t('common:label.loading')}...</h4>}
          >
            <div className="b-infScrollWrapper__normalize">
              <div className="b-belovedUser__list">
                {data.result.map(item => {
                  return <BelovedUserCard key={`user-card-${item.id}`} user={item} />;
                })}
              </div>
            </div>
          </InfiniteScroll>
        </div>
      </>
    );
  };

  return (
    <main className={'b-page'}>
      <div className="b-page__header -desktop">
        <HeadlineOrganization
          orgName={currentOrg?.name || ''}
          orgId={currentOrg?.id || ''}
          headline={t('common:column.beloved-team')}
          customClasses={'-noDesktopMb'}
        />
      </div>
      <OrgBreadcrumbs containerStyles={{ marginBottom: 30 }} />
      <OrgProfileTabs activeItemAlias={'beloved-user'} />
      {renderContent()}
    </main>
  );
};

export default OrgBelovedUserList;
