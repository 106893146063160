import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AdminToolSettingsTeamModel, BelovedUserShortModel, ISelectOptions } from '../../../../../models';
import InputMask from '../../../../controls/Inputs/InputMask';
import { Checkbox, Select } from '../../../../controls';
import Api from '../../../../../services/api';

const mask = [
  { mask: '' },
  {
    mask: 'num',
    lazy: false,
    blocks: {
      num: {
        mask: Number,
        scale: 0,
        thousandsSeparator: ',',
        padFractionalZeros: true,
        radix: '.',
        mapToRadix: [','],
      },
    },
  },
];

interface IProps {
  team: AdminToolSettingsTeamModel;
  onChangeNotification: (
    index: number,
    type: 'email_notification' | 'task_notifications',
    value: boolean,
  ) => void;
  setTeam: (team: AdminToolSettingsTeamModel) => void;
  onChangeNotificationDay: (index: number, value: string) => void;
}

const BelovedTeamNotification: FC<IProps> = ({
  team,
  setTeam,
  onChangeNotification,
  onChangeNotificationDay,
}: IProps) => {
  const { t } = useTranslation();
  const [availableBelovedUsers, setAvailableBelovedUsers] = useState<ISelectOptions[]>([]);

  useEffect(() => {
    Api.get(`beloved_team/`).then(response => {
      if (response) {
        const options: ISelectOptions[] = response.result.map((user: BelovedUserShortModel) => {
          return {
            label: `${user.first_name} ${user.last_name}`,
            value: user.id,
          };
        });
        setAvailableBelovedUsers(options);
      }
    });
  }, []);

  const handleChangeSelect = (values: ISelectOptions[]) => {
    setTeam({
      ...team,
      default_users: values.map(v => v.value) as number[],
    });
  };

  const selectedUsers = team.default_users
    .map(userId => {
      return availableBelovedUsers.find(f => f.value === userId);
    })
    .filter(f => !!f);

  return (
    <div className="b-resourceCard font-grey font-s pr-4">
      <div className="font-weight-bold pb-2">{t('common:headlines.beloved-team-notifications')}</div>
      <div className="pb-3">{t('main:atSettings.notification-sub')}:</div>
      {team.notifications.map((notification, index) => {
        return (
          <>
            <div className="mb-2 mt-2">{notification.name}</div>
            <label
              className="mb-3 d-flex justify-content-between"
              htmlFor={`check-notification-${notification.id}-email`}
            >
              <div className="flex-shrink-1 ml-4">{t(`common:label.email`)}</div>
              <div className="pl-2">
                <Checkbox
                  noText
                  checked={notification.email_notification}
                  id={`check-notification-${notification.id}-email`}
                  onChange={e => onChangeNotification(index, 'email_notification', e.target.checked)}
                />
              </div>
            </label>
            <label
              className="mb-3 d-flex justify-content-between"
              htmlFor={`check-notification-${notification.id}-task`}
            >
              <div className="flex-shrink-1 ml-4">{t(`common:label.task`)}</div>
              <div className="pl-2">
                <Checkbox
                  noText
                  checked={notification.task_notifications}
                  id={`check-notification-${notification.id}-task`}
                  onChange={e => onChangeNotification(index, 'task_notifications', e.target.checked)}
                />
              </div>
            </label>
            {typeof notification.additional_data?.days_after !== 'undefined' ? (
              <div className="d-flex justify-content-between align-items-center ml-4 mb-4">
                <div>{t('main:atSettings.days-after-purchase')}</div>
                <div className="d-flex align-items-center b-credits__simpleInputWrap">
                  <InputMask
                    className="b-credits__simpleInput -w40"
                    value={`${notification.additional_data.days_after}`}
                    ariaLabel={t('main:atSettings.days-after-purchase')}
                    handleChange={(value: string) => onChangeNotificationDay(index, value)}
                    hideErrorBlock
                    mask={mask}
                  />
                </div>
              </div>
            ) : null}
          </>
        );
      })}
      <div className="b-elmDetails__divider" />
      <div className="pb-3">{t('main:atSettings.notification-sub-select')}:</div>
      <Select
        wrapperStyles={{
          width: '80%',
        }}
        isMulti
        placeholder={t('common:label.beloved-team-member')}
        handleChange={value => handleChangeSelect(value)}
        value={selectedUsers}
        options={availableBelovedUsers}
      />
    </div>
  );
};

export default BelovedTeamNotification;
