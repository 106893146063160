import { all } from 'redux-saga/effects';
import { saga as authSaga } from './authData/authDataSaga';
import { saga as utilsSaga } from './utils/utilsSaga';
import { saga as adminToolSaga } from './beloved/adminTools/adminToolSaga';
import { saga as resourcesSaga } from './beloved/resources/resourcesSaga';
import { saga as organizationSaga } from './organization/organizationSaga';
import { saga as profileSaga } from './profile/profileSaga';
import { saga as equityAuditSaga } from './equityAudit/equityAuditSaga';
import { saga as elmSaga } from './elm/elmSaga';
import { saga as taskSaga } from './task/taskSaga';
import { saga as paymentsSaga } from './payments/paymentsSaga';
import { saga as reportSaga } from './report/reportSaga';
import { saga as belOrganizationsSaga } from './beloved/organizations/organizationsSaga';
import { saga as belEquityAuditSaga } from './beloved/equityAudit/equityAuditSaga';
import { saga as belElmSaga } from './beloved/elm/elmSaga';
import { saga as debriefsSaga } from './beloved/debriefs/debriefsSaga';
import { saga as creditSaga } from './credit/creditSaga';
import { saga as dashboardSaga } from './dashboard/dashboardSaga';
import { saga as cohortSaga } from './cohort/cohortSaga';
import { saga as ewpSaga } from './ewp/ewpSaga';
import { saga as avgSaga } from './avg/avgSaga';

export default function* rootSaga() {
  yield all([
    authSaga(),
    utilsSaga(),
    adminToolSaga(),
    resourcesSaga(),
    organizationSaga(),
    profileSaga(),
    equityAuditSaga(),
    taskSaga(),
    paymentsSaga(),
    reportSaga(),
    elmSaga(),
    belOrganizationsSaga(),
    belEquityAuditSaga(),
    belElmSaga(),
    debriefsSaga(),
    creditSaga(),
    dashboardSaga(),
    cohortSaga(),
    ewpSaga(),
    avgSaga(),
  ]);
}
