import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../../store/reducers';
import { belGetOrganizationDetails } from '../../../../store/beloved/organizations/organizationsActions';
import { useParams } from 'react-router';
import { BelovedUserShortModel, IRouteParamsBase, ISelectOptions } from '../../../../models';
import { BelovedOrgTabs, BelovedUserCard, HeadlineOrganization } from '../../../common';
import { useInfiniteList } from '../../../../hooks/common/use-infinite-list';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Button, Loader } from '../../../controls';
import { useTranslation } from 'react-i18next';
import Api from '../../../../services/api';
import SelectValuesModal from '../../../common/SelectValuesModal/SelectValuesModal';
import useHasAccess from '../../../../hooks/beloved/adminTool/user-has-access';
import { BelovedPermissions } from '../../../../constants';

const BelovedOrgUserList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentBelOrg = useSelector((state: StateType) => state.belOrganizations.currentBelOrg);
  const { orgId } = useParams<IRouteParamsBase>();
  const [availableTeam, setAvailableTeam] = useState<ISelectOptions[] | null>(null);
  const { fetchMoreData, fetchData, data } = useInfiniteList<BelovedUserShortModel>(
    `beloved/organizations/${orgId}/get_beloved_team/`,
  );
  let [showModal, setShowModal] = useState(false);
  let [selectedTeam, setSelectedTeam] = useState<number[]>([]);
  const canAssignedBelovedTeam = useHasAccess(BelovedPermissions.ASSIGNED_BELOVED_TEAM);

  useEffect(() => {
    fetchData();
    if (!currentBelOrg) {
      dispatch(
        belGetOrganizationDetails({
          orgId: Number(orgId),
        }),
      );
    }
  }, [orgId]);

  useEffect(() => {
    if (canAssignedBelovedTeam) {
      Api.get(`beloved/organizations/${orgId}/available_beloved_team/`).then(res => {
        if (res) {
          setAvailableTeam(res);
        }
      });
    }
  }, [canAssignedBelovedTeam]);

  useEffect(() => {
    if (data) {
      setSelectedTeam(data.result.map(user => user.id));
    }
  }, [data]);

  const handleClickEdit = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSaveTeam = async (userIds: number[]) => {
    const response = await Api.post(`beloved/organizations/${orgId}/add_beloved_team/`, {
      beloved_team_ids: userIds,
    });
    if (response) {
      setSelectedTeam(userIds);
      setShowModal(false);
      fetchData();
    }
  };

  const renderContent = () => {
    if (!data) {
      return <Loader />;
    }
    return (
      <>
        <div
          className="font-m font-grey font-weight-bold text-align-center mt-4 mb-5"
          style={{ maxWidth: 900 }}
        >
          {data.result.length ? t('main:belovedTeam.assigned-team') : t('main:belovedTeam.no-assigned-team')}
        </div>

        {data.result.length ? (
          <div className="b-infScrollWrapper">
            <InfiniteScroll
              dataLength={data.result.length}
              next={fetchMoreData}
              hasMore={!(data.current_page === data.total_pages)}
              loader={<h4 className="b-no-content">{t('common:label.loading')}...</h4>}
            >
              <div className="b-infScrollWrapper__normalize">
                <div className="b-belovedUser__list">
                  {data.result.map(item => {
                    return <BelovedUserCard key={`user-card-${item.id}`} user={item} />;
                  })}
                </div>
              </div>
            </InfiniteScroll>
          </div>
        ) : null}
        {canAssignedBelovedTeam ? (
          <div className="d-flex justify-content-center mt-5">
            <Button onPress={handleClickEdit} title={t('common:btn.edit-beloved-team')} />
          </div>
        ) : null}
      </>
    );
  };

  return (
    <>
      <main className={'b-page'}>
        <div className="b-page__header -desktop">
          <HeadlineOrganization
            orgName={currentBelOrg?.name || ''}
            orgId={currentBelOrg?.id || ''}
            headline={t('common:column.beloved-team')}
            customClasses={'-noDesktopMb'}
          />
        </div>
        <BelovedOrgTabs activeItemAlias={'beloved-team'} />
        {renderContent()}
      </main>

      {showModal ? (
        <SelectValuesModal
          value={selectedTeam}
          values={availableTeam || []}
          onClose={handleCloseModal}
          title={t('main:belovedTeam.edit-member')}
          handleSave={handleSaveTeam}
        />
      ) : null}
    </>
  );
};

export default BelovedOrgUserList;
