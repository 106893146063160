import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { StateType } from '../../../store/reducers';

const useHasAccess = (alias: string) => {
  const user = useSelector((state: StateType) => state.profile.authUser);
  let [hasAccess, setHasAccess] = useState<null | boolean>(null);

  useEffect(() => {
    if (user) {
      if (user.beloved_user) {
        setHasAccess(user.beloved_user.permissions.includes(alias));
      }
    }
  }, [user]);
  if (hasAccess !== null) {
    return hasAccess;
  }
};

export default useHasAccess;
