import React from 'react';
import { isEmptyObject } from '../../../../../helpers';
import { ROOTS } from '../../../../../routes/routes';
import { useSelector } from 'react-redux';
import { StateType } from '../../../../../store/reducers';
import { useHistory } from 'react-router';
import { EmptyLink } from '../../../../controls';

interface IProps {
  customRoute?: string;
}

const BackTitleButton = ({ customRoute }: IProps) => {
  const history = useHistory();
  const currentOrg = useSelector((state: StateType) => state.organization.currentOrg);
  const defaultsOrgTab = useSelector((state: StateType) => state.organization.defaultsOrgTab);
  const navDetailsSource = useSelector((state: StateType) => state.ewp.navDetailsSource);
  const isBeloved = useSelector((state: StateType) => state.profile.authUser?.beloved_user);

  const handleNavBack = () => {
    if (isBeloved) {
      history.push(navDetailsSource);
      return;
    }
    if (!currentOrg) return null;
    let route: string;
    if (!isEmptyObject(defaultsOrgTab) && defaultsOrgTab[currentOrg.id]) {
      route = defaultsOrgTab[currentOrg.id] as string;
    } else {
      route = ROOTS.ORG_PROFILE;
    }
    history.push((customRoute || route || '').replace(':orgId', `${currentOrg.id}`));
  };

  return <EmptyLink onClick={handleNavBack} className="b-button-icon-back" />;
};

export default BackTitleButton;
