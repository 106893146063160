export enum TaskAlias {
  join = 'req_to_join_org',
  association = 'req_to_associate_org',
  debrief = 'event_debrief',
  auditTeam = 'req_equity_audit_team_user',
  auditManager = 'req_equity_audit_team_manager',
  eventAudit = 'event_equity_audit',
  basicReportAvailable = 'ea_basic_report_available',
  detailedReportAvailable = 'ea_detailed_report_available',
  auditUpcoming = 'upcoming_equity_audit',
  debriefUpcoming = 'upcoming_debrief',
  elmReadyToTake = 'elm_ready_to_take',
  eaAddedResource = 'ea_added_resource',
  elmReportIsAvailable = 'elm_report_is_available',
  inactivityNotification = 'inactivity_notification',
  elmOrgReportIsAvailable = 'elm_org_report_is_available',
  reqEWPUserTeam = 'req_ewp_user_team',
  reqEwpUserTeamConsultant = 'req_ewp_user_team_consultant',
  upcomingCohort = 'upcoming_cohort',
  resourceExpirationNotificationPM = 'resource_expiration_notification_pm',
  resourceExpirationNotificationEATeam = 'resource_expiration_notification_ea_team',
  orgBelovedTeamEaDebriefNotScheduled = 'org_beloved_team_ea_debrief_not_scheduled',
  orgBelovedTeamEaCompleted = 'org_beloved_team_ea_completed',
  orgBelovedTeamElmPublished = 'org_beloved_team_elm_published',
}
