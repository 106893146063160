import React, { useEffect, useState } from 'react';
import { Select, Checkbox, Button, RadioButton } from '../../../controls';
import MyAccountTabs from '../../../common/MyAccountTabs/MyAccountTabs';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../../../../store/reducers';
import { ISelectOptions, SettingsModel, ThemeClassName, ThemeEnum } from '../../../../models';
import { getUser, updateUserSettings } from '../../../../store/profile/profileActions';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { ThemeController } from '../../../common';
import { DARK_MODE_ENABLED } from '../../../../constants';

const languages = [{ label: 'English (US)', value: 'en' }];

const MySettings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state: StateType) => state.profile.authUser)?.user;
  const isBeloved = useSelector((state: StateType) => state.profile.authUser)?.beloved_user;

  const [settings, setSettings] = useState<SettingsModel>({} as SettingsModel);

  useEffect(() => {
    if (user?.settings) {
      setSettings(user.settings);
    }

    return () => {
      if (!user) return;
      let theme = ThemeEnum[Number(user.settings.theme)];
      theme = ThemeController.checkSystemSettings(theme as ThemeClassName);
      document.documentElement.className = theme;
    };
  }, [user]);

  const onChangeColorTheme = (e: any) => {
    if (DARK_MODE_ENABLED) {
      let theme = ThemeEnum[Number(e.target.value)];

      theme = ThemeController.checkSystemSettings(theme as ThemeClassName);
      document.documentElement.className = theme;
      setSettings(prevState => ({
        ...prevState,
        theme: Number(e.target.value),
      }));
    }
  };

  const handleCancel = () => {
    dispatch(getUser({}));
  };

  const handleSubmit = () => {
    dispatch(
      updateUserSettings({
        data: settings,
        callback: () => {
          dispatch(getUser({}));
        },
      }),
    );
  };

  const switchCheckbox = (key: keyof SettingsModel, e: React.ChangeEvent<HTMLInputElement>) => {
    setSettings(prevState => ({
      ...prevState,
      [key]: e.target.checked,
    }));
  };

  const isBlockSaveButton = () => {
    return JSON.stringify(settings) === JSON.stringify(user?.settings);
  };

  const handleChangeLng = (data: ISelectOptions) => {
    i18n.changeLanguage(String(data.value));
    setSettings(prevState => ({
      ...prevState,
      language: String(data.value),
    }));
  };

  return (
    <>
      <main className={'pageContainer pageContainer__content'}>
        <h1 className={'pageContainer__title'}>{t('common:headlines.my-settings')}</h1>
        <MyAccountTabs activeItemAlias={'my-settings'} />
        <div className={'b-formAccount'}>
          <article className={'b-formAccount__part'}>
            <Select
              name={'language'}
              value={languages.find(f => f.value === settings.language)}
              placeholder={t('common:label.preferred-language')}
              ariaLabel="Preferred Language"
              options={languages}
              error={''}
              handleChange={handleChangeLng}
              height={52}
            />
          </article>
          <h2 className={'b-formAccount__subTitle'}>{t('main:settings.theme')}</h2>
          <div className={'b-formAccount__radioPart'}>
            <article className={'b-myProfile -column'}>
              <RadioButton
                title={t('common:radio-btn.system-default')}
                value={ThemeEnum.default}
                selectedValue={settings.theme}
                onChangeValue={onChangeColorTheme}
              />
              <RadioButton
                title={t('common:radio-btn.dark-mode')}
                value={ThemeEnum.dark}
                selectedValue={settings.theme}
                onChangeValue={onChangeColorTheme}
              />
              <RadioButton
                title={t('common:radio-btn.light-mode')}
                value={ThemeEnum.light}
                selectedValue={settings.theme}
                onChangeValue={onChangeColorTheme}
              />
            </article>
            <h2 className={'b-formAccount__subTitle'}>{t('main:settings.notifications-preferences')}</h2>
            <div className={'b-formAccount__radioPart'}>
              <article className={'b-myProfile -column'}>
                <Checkbox id="Email" onChange={() => null} checked={false}>
                  <span className={'checkbox-text'}>{t('common:checkbox.email')}</span>
                </Checkbox>
                <Checkbox id="Application" onChange={() => null} checked={false}>
                  <span className={'checkbox-text'}>{t('common:checkbox.application')}</span>
                </Checkbox>
                {!isBeloved ? (
                  <Checkbox
                    id="show-notification"
                    onChange={e => switchCheckbox('show_task_notification_logic', e)}
                    checked={settings.show_task_notification_logic}
                  >
                    <span className={'checkbox-text'}>{t('common:checkbox.notifications-login')}</span>
                  </Checkbox>
                ) : null}
              </article>
            </div>
            <h2 className={'b-formAccount__subTitle'}>{t('main:settings.my-tasks-preferences')}</h2>
            <div className={'b-formAccount__radioPart'}>
              <article className={'b-myProfile -column'}>
                <Checkbox
                  id="Delete confirmation"
                  onChange={e => switchCheckbox('confirmation_popup', e)}
                  checked={settings.confirmation_popup}
                >
                  <span className={'checkbox-text'}>{t('common:checkbox.delete-confirmation')}</span>
                </Checkbox>
              </article>
            </div>
          </div>
          <div className={'b-formAccount__buttons'}>
            <Button
              onPress={handleCancel}
              title={t('common:btn.cancel')}
              size={'small'}
              disabled={isBlockSaveButton()}
              type={'transparency'}
            />
            <Button
              onPress={handleSubmit}
              title={t('common:btn.save')}
              size={'small'}
              disabled={isBlockSaveButton()}
              type={'orange-light'}
            />
          </div>
        </div>
      </main>
    </>
  );
};

export default MySettings;
