import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

const configureI18n = () => {
  i18n
    // load translation using http -> see /public/locales
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    // .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      lng: 'en',
      fallbackLng: 'en',
      preload: ['en'],
      debug: false,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      ns: ['auth', 'common', 'awa', 'main', 'pp'],
      backend: {
        loadPath: `${window.origin}/locales/{{lng}}/{{ns}}.json?cb=7`,
      },
    });
};

export default configureI18n;
